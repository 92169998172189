import React, { ReactElement } from 'react';
import { Box, BoxProps, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Link, LinkProps } from '../Link';

export type SkipLinkOriginProps = {
  componentLocale?: Locale;
  id: string;
  labels: {
    en: string;
    fi: string;
  };
  linkProps?: Omit<LinkProps, 'newTab' | 'href' | 'children'>;
} & BoxProps;

const Origin = ({
  componentLocale,
  id,
  labels,
  linkProps,
  ...rest
}: SkipLinkOriginProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Box position={'relative'}>
      <Box
        backgroundColor={'white'}
        sx={{
          'position': 'absolute',
          'top': 4,
          'left': 4,
          '&:not(:focus):not(:focus-within):not(:active)': {
            border: 0,
            clip: 'rect(0px, 0px, 0px, 0px)',
            height: 1,
            width: 1,
            margin: -1,
            padding: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            position: 'absolute',
          },
        }}
        {...rest}
      >
        <Link
          variant={'box'}
          href={`#${id}-${locale}`}
          lang={lang}
          paddingX={'1em'}
          paddingY={'0.5em'}
          display={'block'}
          backgroundColor={'ui.primary'}
          color={'text.onPrimary'}
          {...linkProps}
        >
          {labels[locale]}
        </Link>
      </Box>
    </Box>
  );
};

export type SkipLinkDestinationProps = {
  componentLocale?: Locale;
  id: string;
  labels: {
    en: string;
    fi: string;
  };
};

const Destination = ({
  componentLocale,
  id,
  labels,
}: SkipLinkDestinationProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <VisuallyHidden lang={lang} tabIndex={-1} id={`${id}-${locale}`}>
      {labels[locale]}
    </VisuallyHidden>
  );
};

export const SkipLink = { Origin, Destination };
