import { hexToHsluv } from '../utils/color';
import { ColorMode } from '../utils/types';

type Color = {
  key: string;
  name: string;
  light: string;
  dark: string;
};

const getColorObject = (colors: Color[], colorMode: ColorMode) =>
  Object.fromEntries(
    colors.map(({ key, [colorMode]: colorModeKey }) => [key, colorModeKey])
  );

const getColorArray = (colors: Color[], colorMode: ColorMode) =>
  colors.map(({ name, [colorMode]: colorModeKey }) => ({
    title: name,
    color: colorModeKey,
  }));

const getUniqueColors = (array: { color: string }[]) =>
  array
    .map(({ color }) => color)
    .filter(
      (color, index, array) =>
        color.length === 7 && array.indexOf(color) === index
    )
    .map<[number[], string]>((color) => [hexToHsluv(color) || [], color])
    .sort((a, b) => b[0][2] - a[0][2] || a[0][0] - b[0][0] || a[0][1] - b[0][1])
    .map((color) => color[1]);

const ui = [
  {
    key: 'primary',
    name: 'UI Primary',
    light: '#101016',
    dark: '#F9FCFB',
  },
  {
    key: 'secondary',
    name: 'UI Secondary',
    light: '#FFFFFF',
    dark: '#161624',
  },
  {
    key: 'background',
    name: 'UI Background',
    light: '#F9FCFB',
    dark: '#161624',
  },
  {
    key: 'surface',
    name: 'UI Surface',
    light: '#E5EEF0',
    dark: '#242432',
  },
  {
    key: 'scrollbar',
    name: 'UI Scrollbar',
    light: '#ABABAB',
    dark: '#ABABAB',
  },
  {
    key: 'shadow',
    name: 'UI Shadow',
    light: '#00000033',
    dark: '#00000088',
  },
];

const text = [
  {
    key: 'default',
    name: 'Text Default',
    light: '#101016',
    dark: '#F9FCFB',
  },
  {
    key: 'light',
    name: 'Text Light',
    light: '#646464',
    dark: '#8090A0',
  },
  {
    key: 'onPrimary',
    name: 'Text On Primary',
    light: '#FFFFFF',
    dark: '#161624',
  },
  {
    key: 'onSecondary',
    name: 'Text On Secondary',
    light: '#101016',
    dark: '#F9FCFB',
  },
  {
    key: 'onColor',
    name: 'Text On Color',
    light: '#101016',
    dark: '#161624',
  },
];

const pink = [
  {
    key: '200',
    name: 'Pink 200',
    light: '#FFE7E8',
    dark: '#F4C8CA',
  },
  {
    key: '500',
    name: 'Pink 500',
    light: '#F4C8CA',
    dark: '#F36B74',
  },
  {
    key: '800',
    name: 'Pink 800',
    light: '#F36B74',
    dark: '#C04048',
  },
];

const green = [
  {
    key: '200',
    name: 'Green 200',
    light: '#C3E9DF',
    dark: '#9FD0C4',
  },
  {
    key: '500',
    name: 'Green 500',
    light: '#9FD0C4',
    dark: '#5DC2A9',
  },
  {
    key: '800',
    name: 'Green 800',
    light: '#5DC2A9',
    dark: '#28A485',
  },
];

const yellow = [
  {
    key: '200',
    name: 'Yellow 200',
    light: '#FFECD0',
    dark: '#FFDCA7',
  },
  {
    key: '500',
    name: 'Yellow 500',
    light: '#FFDCA7',
    dark: '#FFC164',
  },
  {
    key: '800',
    name: 'Yellow 800',
    light: '#FFC164',
    dark: '#EE9613',
  },
];

const purple = [
  {
    key: '200',
    name: 'Purple 200',
    light: '#EFEAFF',
    dark: '#D4CAF2',
  },
  {
    key: '500',
    name: 'Purple 500',
    light: '#D4CAF2',
    dark: '#A283FF',
  },
  {
    key: '800',
    name: 'Purple 800',
    light: '#A283FF',
    dark: '#784CFC',
  },
];

const blue = [
  {
    key: '200',
    name: 'Blue 200',
    light: '#E1EEFC',
    dark: '#C3D8EE',
  },
  {
    key: '500',
    name: 'Blue 500',
    light: '#C3D8EE',
    dark: '#83BFFF',
  },
  {
    key: '800',
    name: 'Blue 800',
    light: '#83BFFF',
    dark: '#3E94F0',
  },
];

const system = [
  {
    key: 'black',
    name: 'System Black',
    light: '#000000',
    dark: '#000000',
  },
  {
    key: 'white',
    name: 'System White',
    light: '#FFFFFF',
    dark: '#FFFFFF',
  },
  {
    key: 'focus',
    name: 'System Focus',
    light: '#673AF4',
    dark: '#8864FF',
  },
  {
    key: 'success',
    name: 'System Success',
    light: '#006D52',
    dark: '#0A5A46',
  },
  {
    key: 'notification',
    name: 'System Notification',
    light: '#1B69BD',
    dark: '#145088',
  },
  {
    key: 'error',
    name: 'System Error',
    light: '#BE2F38',
    dark: '#AE2029',
  },
  {
    key: 'inputText',
    name: 'System Input Text',
    light: '#000000',
    dark: '#F9FCFB',
  },
  {
    key: 'inputPlaceholder',
    name: 'System Input Placeholder',
    light: '#808080',
    dark: '#A8B7C6',
  },
  {
    key: 'inputBackground',
    name: 'System Input Background',
    light: '#FFFFFF',
    dark: '#464656',
  },
];

const objectLight = {
  ui: getColorObject(ui, 'light'),
  text: getColorObject(text, 'light'),
  pink: getColorObject(pink, 'light'),
  green: getColorObject(green, 'light'),
  yellow: getColorObject(yellow, 'light'),
  purple: getColorObject(purple, 'light'),
  blue: getColorObject(blue, 'light'),
  system: getColorObject(system, 'light'),
};

const matrixLight = [ui, text, pink, green, yellow, purple, blue, system];

const arrayLight = [
  ...getColorArray(ui, 'light'),
  ...getColorArray(text, 'light'),
  ...getColorArray(pink, 'light'),
  ...getColorArray(green, 'light'),
  ...getColorArray(yellow, 'light'),
  ...getColorArray(purple, 'light'),
  ...getColorArray(blue, 'light'),
  ...getColorArray(system, 'light'),
];

const uniqueLight = getUniqueColors(arrayLight);

export const light = {
  object: objectLight,
  matrix: matrixLight,
  array: arrayLight,
  unique: uniqueLight,
};

const objectDark = {
  ui: getColorObject(ui, 'dark'),
  text: getColorObject(text, 'dark'),
  pink: getColorObject(pink, 'dark'),
  green: getColorObject(green, 'dark'),
  yellow: getColorObject(yellow, 'dark'),
  purple: getColorObject(purple, 'dark'),
  blue: getColorObject(blue, 'dark'),
  system: getColorObject(system, 'dark'),
};

const matrixDark = [ui, text, pink, green, yellow, purple, blue, system];

const arrayDark = [
  ...getColorArray(ui, 'dark'),
  ...getColorArray(text, 'dark'),
  ...getColorArray(pink, 'dark'),
  ...getColorArray(green, 'dark'),
  ...getColorArray(yellow, 'dark'),
  ...getColorArray(purple, 'dark'),
  ...getColorArray(blue, 'dark'),
  ...getColorArray(system, 'dark'),
];

const uniqueDark = getUniqueColors(arrayDark);

export const dark = {
  object: objectDark,
  matrix: matrixDark,
  array: arrayDark,
  unique: uniqueDark,
};

export const colors = { light, dark };

export const COLOR_SCHEMES = [
  'pink',
  'green',
  'yellow',
  'purple',
  'blue',
] as const;

export type ColorScheme = typeof COLOR_SCHEMES[number];
