import { spaceToSpace } from '.';

/* define regex to match CSS string format for hex, rgb, and hsl */
const REGEX =
  /#([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})(?:\s|$)|rgb\((\d+),\s*([\d.]+),\s*([\d.]+)\)|hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/;

export const parseToRgb = (string: string): number[] | null => {
  /* get array of matches (whole matches and capturing groups) */
  let matches = string.match(REGEX);

  /* if no match, return null */
  if (matches === null || !matches[0]) {
    return null;
  }

  /* keep only the values for the whole match and its capturing groups */
  matches = matches.filter((match) => match && match !== undefined);

  /* if no matches left, return null */
  if (!matches[0]) {
    return null;
  }

  /* destructure whole match (color) and the rest (values) */
  const [color, ...values] = matches;

  /* parse the integer from each string (base 16 for hex codes, base 10 otherwise */
  const integers = values.map((value) =>
    parseInt(value, color.startsWith('#') ? 16 : 10)
  );

  /* if color is hex or rgb... */
  if (color.startsWith('#') || color.startsWith('rgb')) {
    /* check that all values are 0-255 */
    const invalid = integers.some((integer) => integer < 0 || integer > 255);

    /* return null or integers, depending on the case */
    return invalid ? null : integers;
  }

  /* if color is hsl... */
  if (color.startsWith('hsl')) {
    /* check that the first value is 0-360, and that the others are 0-100*/
    const invalid = integers.some(
      (integer, i) =>
        integer < 0 || (i === 0 && integer > 360) || (i > 0 && integer > 100)
    );

    /* return null or object, depending on the case */
    return invalid ? null : spaceToSpace(integers, 'hsl', 'rgb');
  }

  return null;
};
