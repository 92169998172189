import React, { ReactElement, ReactNode } from 'react';
import { Global, Interpolation, Theme } from '@emotion/react';
import {
  ChakraProvider,
  extendTheme,
  ThemeProvider,
  useColorMode,
} from '@chakra-ui/react';
import { theme } from '../../theme';
import { global } from '../../theme/global';
import { colors } from '../../theme/colors';

export type ThemeContextProps = {
  children: ReactNode;
};

export const ThemeContext = ({ children }: ThemeContextProps): ReactElement => (
  <ChakraProvider theme={theme}>
    <Global styles={global} />
    <ColorModeContext>{children}</ColorModeContext>
  </ChakraProvider>
);

export const ColorModeContext = ({
  children,
}: ThemeContextProps): ReactElement => {
  const { colorMode } = useColorMode();

  return (
    <ThemeProvider
      theme={extendTheme(
        {
          colors: colors[colorMode].object,
          shadows: {
            base: `0.25rem 0.25rem 1rem 0 ${colors[colorMode].object.ui.shadow}`,
            hover: `0 1rem 1rem 0 ${colors[colorMode].object.ui.shadow}`,
            outline: `0 0 0 2px white, 0 0 0 4px ${colors[colorMode].object.system.focus}`,
            outlineWithoutOffset: `0 0 0 2px ${colors[colorMode].object.system.focus}`,
          },
        },
        theme
      )}
    >
      <Global
        styles={
          {
            '*': {
              'scrollbarColor': `${colors[colorMode].object.ui.scrollbar} transparent`,
              'scrollbarFaceColor': colors[colorMode].object.ui.scrollbar,
              'scrollbarTrackColor': 'transparent',
              'scrollbarWidth': 'thin',
              '&::-webkit-scrollbar': {
                height: 10,
                width: 10,
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors[colorMode].object.ui.scrollbar,
                borderColor: colors[colorMode].object.ui.background,
                borderRadius: 4,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderRightWidth: 3,
                borderBottomWidth: 0,
                borderLeftWidth: 3,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: colors[colorMode].object.ui.background,
              },
              'body': {
                backgroundColor: colors[colorMode].object.ui.background,
                color: colors[colorMode].object.text.default,
              },
            },
          } as unknown as Interpolation<Theme>
        }
      />
      {children}
    </ThemeProvider>
  );
};
