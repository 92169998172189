exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-templates-event-collection-tsx": () => import("./../../../src/templates/EventCollection.tsx" /* webpackChunkName: "component---src-templates-event-collection-tsx" */),
  "component---src-templates-event-post-imported-tsx": () => import("./../../../src/templates/EventPostImported.tsx" /* webpackChunkName: "component---src-templates-event-post-imported-tsx" */),
  "component---src-templates-event-post-tsx": () => import("./../../../src/templates/EventPost.tsx" /* webpackChunkName: "component---src-templates-event-post-tsx" */),
  "component---src-templates-frontpage-tsx": () => import("./../../../src/templates/Frontpage.tsx" /* webpackChunkName: "component---src-templates-frontpage-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../../../src/templates/Generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-news-collection-tsx": () => import("./../../../src/templates/NewsCollection.tsx" /* webpackChunkName: "component---src-templates-news-collection-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/NewsPost.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */)
}

