import React, { ReactElement } from 'react';
import { HStack, Text, TextProps, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { formatDateTime, formatDateTimeRange } from '../../utils/datetime';
import { Icon } from '../Icon';

export type DateTimeProps = {
  componentLocale?: Locale;
  condensed?: boolean;
  dateOnly?: boolean;
  start: string;
  end?: string;
  withoutIcon?: boolean;
} & Omit<TextProps, 'as'>;

export const DateTime = ({
  componentLocale,
  condensed,
  dateOnly,
  start,
  end,
  withoutIcon,
  ...rest
}: DateTimeProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Text
      as={'time'}
      lang={lang}
      dateTime={!end || start === end ? start : `${start}/${end}`}
      fontSize={'xs'}
      fontWeight={700}
      {...rest}
    >
      <HStack align={'flex-start'} as={'span'}>
        {withoutIcon ? null : <Icon.Calendar fontSize={'1.5em'} />}
        {!condensed ? (
          <span>
            {!end || start === end
              ? formatDateTime(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  dateOnly
                )
              : formatDateTimeRange(
                  locale === 'en' ? 'en-GB' : locale,
                  start,
                  end
                )}
          </span>
        ) : (
          <span>
            <span aria-hidden>
              {!end || start === end
                ? formatDateTime(
                    locale === 'en' ? 'en-GB' : locale,
                    start,
                    dateOnly,
                    condensed
                  )
                : formatDateTimeRange(
                    locale === 'en' ? 'en-GB' : locale,
                    start,
                    end,
                    condensed
                  )}
            </span>
            <VisuallyHidden>
              {!end || start === end
                ? formatDateTime(
                    locale === 'en' ? 'en-GB' : locale,
                    start,
                    dateOnly
                  )
                : formatDateTimeRange(
                    locale === 'en' ? 'en-GB' : locale,
                    start,
                    end
                  )}
            </VisuallyHidden>
          </span>
        )}
      </HStack>
    </Text>
  );
};
