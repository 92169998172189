import React, { ReactElement } from 'react';
import { Box, BoxProps, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { UNDERLINE_SX } from '../CTA/misc';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { DisclosureColorScheme, TRANS } from './misc';

export type DisclosureProps = {
  componentLocale?: Locale;
  colorScheme?: DisclosureColorScheme;
  title: string;
} & BoxProps;

export const Disclosure = ({
  children,
  componentLocale,
  colorScheme = 'pink',
  title,
  ...rest
}: DisclosureProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const { isOpen, onToggle } = useDisclosure();

  return isEmptyNode(children) ? null : (
    <Box
      backgroundColor={
        colorScheme === 'outline'
          ? 'transparent'
          : colorScheme === 'gray'
          ? 'ui.surface'
          : `${colorScheme}.500`
      }
      borderColor={colorScheme === 'outline' ? 'ui.primary' : 'transparent'}
      borderRadius={'m'}
      borderWidth={2}
      color={
        colorScheme === 'outline' || colorScheme === 'gray'
          ? 'text.default'
          : 'text.onColor'
      }
      marginY={'1rem'}
      {...rest}
    >
      <Button
        aria-expanded={isOpen}
        aria-controls={title + locale + colorScheme}
        onClick={onToggle}
        variant={'unstyled'}
        color={
          colorScheme === 'outline' || colorScheme === 'gray'
            ? 'text.default'
            : 'text.onColor'
        }
        display={'block'}
        paddingX={'1.7rem'}
        paddingY={'1.4rem'}
        width={'100%'}
        sx={{
          '& .cta-underline': { ...UNDERLINE_SX.base, padding: 0, margin: 0 },
          '&:hover .cta-underline': UNDERLINE_SX.interaction,
        }}
      >
        <Flex width={'100%'} align={'baseline'} justify={'space-between'}>
          <Box whiteSpace={'normal'} textAlign={'left'} marginEnd={'1rem'}>
            <Heading
              level={4}
              as={'div'}
              withoutLine
              color={
                colorScheme === 'outline' || colorScheme === 'gray'
                  ? 'text.default'
                  : 'text.onColor'
              }
              marginTop={0}
              marginBottom={0}
              className={'cta-underline'}
            >
              {title}
            </Heading>
          </Box>
          <Box aria-hidden>
            <Box as={'span'}>{TRANS[isOpen ? 'hide' : 'show'][locale]}</Box>
            <Icon.Plus
              fontSize={'1.5em'}
              marginTop={'-0.1em'}
              marginBottom={'0.05em'}
              marginStart={'0.1rem'}
              marginEnd={'-0.25rem'}
              transform={isOpen ? 'rotate(45deg)' : 'rotate(0)'}
              transitionProperty={'transform'}
              transitionDuration={'slow'}
            />
          </Box>
        </Flex>
      </Button>
      <Box id={title + locale + colorScheme}>
        <Collapse in={isOpen} animateOpacity>
          <Box
            paddingX={'1.9rem'}
            paddingY={'1.5rem'}
            paddingTop={0}
            sx={{
              '& p:first-of-type': {
                marginTop: 0,
              },
              '& p:last-of-type': {
                marginBottom: 0,
              },
            }}
          >
            {children}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
