export const BUTTON_SIZES = ['s', 'm', 'l'] as const;

export type ButtonSize = typeof BUTTON_SIZES[number];

export const BUTTON_SX_SIZES = {
  s: { fontSize: 'xs', paddingX: '1.2em', paddingY: '0.4em' },
  m: { fontSize: 's', paddingX: '1.4em', paddingY: '0.7em' },
  l: { fontSize: 'm', paddingX: '1.5em', paddingY: '0.6em' },
};

export const BUTTON_VARIANTS = [
  'solid',
  'outline',
  'ghost',
  'unstyled',
] as const;

export type ButtonVariant = typeof BUTTON_VARIANTS[number];

export const COMMON = {
  borderWidth: 2,
  borderRadius: 'm',
  fontWeight: 700,
  height: 'auto',
  lineHeight: 1.25,
};

export const SOLID = {
  backgroundColor: 'ui.primary',
  borderColor: 'ui.primary',
  color: 'text.onPrimary',
};

export const OUTLINE = {
  backgroundColor: 'transparent',
  borderColor: 'ui.primary',
  color: 'ui.primary',
};

export const GHOST = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: 'ui.primary',
};

export const ACTIVE = {
  backgroundColor: 'ui.surface',
  borderColor: 'ui.primary',
  color: 'ui.primary',
};

export const BUTTON_SX_VARIANTS = {
  solid: {
    ...COMMON,
    ...SOLID,
    _active: { ...ACTIVE },
    _hover: { ...OUTLINE },
  },
  ghost: {
    ...COMMON,
    ...GHOST,
    _active: { ...ACTIVE },
    _hover: { ...SOLID },
    sx: {
      '&:focus:not(:hover)': {
        outline: '2px solid white',
        outlineOffset: '-1px',
      },
    },
  },
  outline: {
    ...COMMON,
    ...OUTLINE,
    _active: { ...ACTIVE },
    _hover: { ...SOLID },
  },
  unstyled: {
    ...COMMON,
    ...GHOST,
    _active: {},
    _hover: {},
    sx: {},
  },
};
