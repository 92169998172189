import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { RichText, RichTextData } from '../RichText';
import { ContactForm, ContactFormProps } from '.';

export type ContactFormWithQueryProps = Pick<ContactFormProps, 'largeButton'>;

type ContactFormSubquery = {
  contactFormTitle?: string;
  contactFormDescription?: RichTextData;
  contactFormSubmissionSuccessMessage?: string;
  contactFormSubmissionFailureMessage?: string;
};

type ContactFormQuery = {
  en: ContactFormSubquery;
  fi: ContactFormSubquery;
};

export const ContactFormWithQuery = (
  props: ContactFormWithQueryProps
): ReactElement => {
  const data: ContactFormQuery = useStaticQuery(query);
  const { locale = 'fi' } = usePageContext();
  const {
    contactFormTitle,
    contactFormDescription,
    contactFormSubmissionSuccessMessage,
    contactFormSubmissionFailureMessage,
  } = data[locale];

  return (
    <ContactForm
      {...props}
      title={contactFormTitle}
      description={<RichText data={contactFormDescription} />}
      successMessage={contactFormSubmissionSuccessMessage}
      failureMessage={contactFormSubmissionFailureMessage}
    />
  );
};

const query = graphql`
  {
    en: contentfulSite(node_locale: { eq: "en" }) {
      ...SiteContactForm
    }
    fi: contentfulSite(node_locale: { eq: "fi" }) {
      ...SiteContactForm
    }
  }
`;
