export const TRANS = {
  language: {
    en: 'Language',
    fi: 'Kieli',
  },
  en: {
    en: 'In English',
    fi: 'Englanniksi',
  },
  fi: {
    en: 'In Finnish',
    fi: 'Suomeksi',
  },
};
