import React, { ReactElement } from 'react';
import { BoxProps, Text } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type QuoteProps = Omit<BoxProps, 'as'>;

export const Quote = ({ children, ...rest }: QuoteProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Text
      as={'blockquote'}
      borderColor={'text.default'}
      borderStartWidth={3}
      display={'block'}
      fontFamily={'heading'}
      fontSize={'l'}
      lineHeight={1.5}
      marginY={'1em'}
      paddingStart={'1em'}
      {...rest}
    >
      {children}
    </Text>
  );
