import React, { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type EmphasisProps = Omit<TextProps, 'as'>;

export const Emphasis = ({
  children,
  ...rest
}: EmphasisProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Text as="em" fontStyle="italic" {...rest}>
      {children}
    </Text>
  );
