import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export type ContactFormContextProps = {
  children: ReactNode;
};

const initialValues = {
  name: '',
  email: '',
  email2: '',
  subject: '',
  message: '',
};

type Values = typeof initialValues;

export type ContactFormContext = {
  initialValues: Values;
  values: Values;
  setValues: Dispatch<SetStateAction<Values>>;
};

const Context = createContext<ContactFormContext>({
  initialValues: initialValues,
  values: initialValues,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setValues: () => {},
});

export const ContactFormContext = (
  props: ContactFormContextProps
): ReactElement => {
  const [values, setValues] = useState(initialValues);

  return (
    <Context.Provider
      value={{
        initialValues,
        values,
        setValues,
      }}
      {...props}
    />
  );
};

export const useContactFormContext = (): ContactFormContext =>
  useContext(Context);
