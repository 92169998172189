import React, { ReactElement } from 'react';
import {
  Box,
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  useBreakpointValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import { Hyphenate } from '../Hyphenate';
import { Link } from '../Link';
import { HeadingLevel, HEADING_HYPHENATION, HEADING_SX } from './misc';

export type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
  level?: HeadingLevel;
  withoutLine?: boolean;
} & Omit<ChakraHeadingProps, 'as'>;

export const Heading = ({
  children,
  as,
  color = 'text.default',
  id,
  level = 2,
  sx,
  withoutLine,
  marginTop = '2rem',
  marginBottom = '1rem',
  _first,
  _last,
  ...rest
}: HeadingProps): ReactElement | null => {
  const minWordLength = useBreakpointValue(HEADING_HYPHENATION[level]);

  return isEmptyNode(children) ? null : (
    <Box
      marginTop={marginTop}
      marginBottom={marginBottom}
      _first={_first}
      _last={_last}
    >
      <ChakraHeading
        id={id}
        as={as || `h${level}`}
        color={color}
        sx={{
          '& code': { fontWeight: 500 },
          '& strong': { fontWeight: 900 },
          '& .headingAnchor': {
            fontFamily: 'body',
            fontWeight: 400,
            opacity: 0,
            textDecoration: 'none',
          },
          '&:hover': { '& .headingAnchor': { opacity: 0.5 } },
          '& .headingAnchor:hover, & .headingAnchor:focus, & .headingAnchor:active':
            { opacity: 1 },
          ...sx,
        }}
        {...HEADING_SX[level]}
        {...rest}
      >
        {!id ? (
          <Hyphenate minWordLength={minWordLength}>{children}</Hyphenate>
        ) : (
          <>
            <span aria-hidden>
              <Hyphenate minWordLength={minWordLength}>{children}</Hyphenate>
            </span>
            <Link
              className={'headingAnchor'}
              href={`#${id}`}
              marginX={'0.2em'}
              paddingX={'0.2em'}
              variant={'box'}
              _hover={{}}
            >
              <span aria-hidden>#</span>
              <VisuallyHidden>{children}</VisuallyHidden>
            </Link>
          </>
        )}
        {withoutLine ? null : (
          <Box
            as={'span'}
            display={'block'}
            backgroundColor={color}
            height={3}
            width={level <= 4 ? '5rem' : '2.5rem'}
            marginTop={'1rem'}
          />
        )}
      </ChakraHeading>
    </Box>
  );
};
