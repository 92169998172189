import React, { ReactElement } from 'react';
import {
  Box,
  BoxProps,
  LinkBox,
  LinkOverlay,
  Text,
  TextProps,
  useColorMode,
  useToken,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { prettifyUrl, protocolizeUrl } from '../../utils/string';
import { Locale } from '../../utils/types';
import { Image, ImageProps } from '../Image';
import { TRANS } from './misc';

export type FigureProps = {
  caption?: ReactElement;
  captionProps?: Omit<TextProps, 'as'>;
  componentLocale?: Locale;
  reference?: string | number;
  transparentBackground?: boolean;
  url?: string;
  image?: ImageProps['image'];
  imageForDarkTheme?: ImageProps['image'];
  textAlternative?: ImageProps['textAlternative'];
} & Omit<BoxProps, 'as'>;

export const Figure = ({
  caption,
  captionProps,
  componentLocale,
  image: imageLight,
  imageForDarkTheme: imageDark,
  reference,
  textAlternative,
  transparentBackground,
  url,
  ...rest
}: FigureProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);
  const [focusOutline] = useToken('shadows', ['outline']);
  const { colorMode } = useColorMode();
  const image = colorMode === 'dark' && imageDark ? imageDark : imageLight;
  url = !url ? undefined : protocolizeUrl(url);

  return !image ? null : (
    <Box
      marginY={'2em'}
      {...(url && {
        as: LinkBox,
        sx: {
          '& .figureOverlay': {
            position: 'relative',
            outline: '2px solid transparent',
          },
          '& .figureOverlay::after': {
            borderColor: 'transparent',
            borderRadius: 'l',
            borderWidth: 2,
            content: '""',
            position: 'absolute',
            top: -4,
            right: -4,
            bottom: -4,
            left: -4,
            transitionProperty:
              'top, right, bottom, left, border-color, box-shadow',
            transitionDuration: 'normal',
            transitionTimingFunction: 'ease-out',
            zIndex: 100,
          },
          '&:hover': {
            '& .figureOverlay::after': {
              borderColor: 'ui.primary',
            },
          },
          '&:focus-within': {
            '& .figureOverlay::after': {
              boxShadow: focusOutline,
            },
          },
          '&:focus-within:not(:hover)': {
            '& .figureOverlay::after': {
              outline: '2px solid white',
              outlineOffset: '-1px',
            },
          },
        },
      })}
      {...rest}
    >
      <Box lang={lang} as={'figure'}>
        {url ? (
          <LinkOverlay href={url} className={'figureOverlay'}>
            <VisuallyHidden>
              {textAlternative || prettifyUrl(url)}
            </VisuallyHidden>
            <Box
              borderRadius={'m'}
              overflow={'hidden'}
              backgroundColor={transparentBackground ? undefined : 'ui.surface'}
            >
              <Box opacity={transparentBackground ? 1 : 0.9}>
                <Image image={image} />
              </Box>
            </Box>
          </LinkOverlay>
        ) : (
          <Box
            borderRadius={'m'}
            overflow={'hidden'}
            backgroundColor={transparentBackground ? undefined : 'ui.surface'}
          >
            <Box opacity={transparentBackground ? 1 : 0.9}>
              <Image image={image} textAlternative={textAlternative} />
            </Box>
          </Box>
        )}
        {(reference || caption) && (
          <Text
            as={'figcaption'}
            color={'text.light'}
            fontSize={'xs'}
            lineHeight={1.5}
            marginY={'1em'}
            {...captionProps}
          >
            {reference && caption ? (
              <>
                {TRANS.figure[locale]} {reference}: {caption}
              </>
            ) : reference ? (
              <>
                {TRANS.figure[locale]} {reference}
              </>
            ) : (
              caption
            )}
          </Text>
        )}
      </Box>
    </Box>
  );
};
