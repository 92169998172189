import { Icon } from '../Icon';

export const CALLOUT_VARIANTS = ['positive', 'neutral', 'negative'] as const;

export type CalloutVariant = typeof CALLOUT_VARIANTS[number];

export const CALLOUT_SX = {
  positive: {
    backgroundColor: 'green.200',
    borderColor: 'green.800',
    color: 'system.success',
    sx: { '& code': { backgroundColor: '#00000009' } },
  },
  neutral: {
    backgroundColor: 'blue.200',
    borderColor: 'blue.800',
    color: 'system.notification',
    sx: { '& code': { backgroundColor: '#00000009' } },
  },
  negative: {
    backgroundColor: 'pink.200',
    borderColor: 'pink.800',
    color: 'system.error',
    sx: { '& code': { backgroundColor: '#00000009' } },
  },
};

export const CALLOUT_ICONS = {
  positive: Icon.CheckCircle,
  neutral: Icon.InfoCircle,
  negative: Icon.ExclamationCircle,
};

export const CALLOUT_ALT_VARIANTS = ['feedback', 'guidance'] as const;

export type CalloutAltVariant = typeof CALLOUT_ALT_VARIANTS[number];

export const CALLOUT_ALTS = {
  feedback: {
    positive: {
      en: 'Success',
      fi: 'Onnistunut',
    },
    neutral: {
      en: 'Note',
      fi: 'Huomio',
    },
    negative: {
      en: 'Error',
      fi: 'Virhe',
    },
  },
  guidance: {
    positive: {
      en: 'Yes',
      fi: 'Kyllä',
    },
    neutral: {
      en: 'Note',
      fi: 'Huomio',
    },
    negative: {
      en: 'No',
      fi: 'Ei',
    },
  },
};
