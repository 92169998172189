import {
  ButtonSize,
  BUTTON_SIZES,
  BUTTON_SX_SIZES,
  BUTTON_SX_VARIANTS,
} from '../Button/misc';
import { TRANS as LINK_TRANS } from '../Link/misc';

export const TRANS = {
  newTab: LINK_TRANS.newTab,
  readMore: {
    en: 'Read more',
    fi: 'Lue lisää',
  },
};

export const CTA_SIZES = BUTTON_SIZES;
export type CTASize = ButtonSize;
export const CTA_SX_SIZES = BUTTON_SX_SIZES;

export const UNDERLINE_SX = {
  base: {
    backgroundImage: 'linear-gradient(currentColor, currentColor)',
    backgroundPosition: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '0% 2px',
    transitionProperty: 'background-size',
    transitionDuration: 'slower',
    transitionTimingFunction: 'ease',
    paddingBottom: 4,
    marginTop: 2,
    marginBottom: 1,
  },
  interaction: {
    backgroundSize: '100% 2px',
    backgroundPositionX: '0%',
  },
};

export const CTA_VARIANTS = ['solid', 'outline', 'underline'] as const;
export type CTAVariant = typeof CTA_VARIANTS[number];
export const CTA_SX_VARIANTS = {
  solid: BUTTON_SX_VARIANTS.solid,
  outline: BUTTON_SX_VARIANTS.outline,
  underline: {
    ...BUTTON_SX_VARIANTS.unstyled,
    borderRadius: 's',
    padding: 0,
    borderWidth: 0,
    sx: {
      '&:focus': {
        outline: '2px solid white',
        outlineOffset: '-1px',
      },
      '& .cta-underline': UNDERLINE_SX.base,
      '&:hover .cta-underline, &:focus .cta-underline':
        UNDERLINE_SX.interaction,
    },
  },
};
