import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { Link } from '../Link';
import { RichText, RichTextData } from '../RichText';
import { Footer, FooterProps } from '.';

type FooterSubquery = {
  facebook: string;
  instagram: string;
  area1Body: RichTextData;
  area2Body: RichTextData;
  area3Items: { slug: string; title: string }[];
} & Omit<FooterProps, 'contact' | 'area1Body' | 'area2Body' | 'area3Items'>;

type FooterQuery = {
  en: FooterSubquery;
  fi: FooterSubquery;
};

export const FooterWithQuery = (): ReactElement => {
  const data: FooterQuery = useStaticQuery(query);
  const { locale = 'fi' } = usePageContext();
  const { facebook, instagram, area1Body, area2Body, area3Items, ...rest } =
    data[locale];
  const contact = {
    order: ['facebook', 'instagram'],
    facebook: facebook,
    instagram: instagram,
  };

  return (
    <Footer
      contact={contact}
      area1Body={<RichText data={area1Body} footer />}
      area2Body={<RichText data={area2Body} footer />}
      area3Items={area3Items?.map(({ slug, title }) => (
        <Link
          key={slug}
          href={`/${locale}/${slug}`}
          variant={'reverseUnderline'}
          color={'text.light'}
        >
          {title}
        </Link>
      ))}
      {...rest}
    />
  );
};

const query = graphql`
  {
    en: contentfulSite(node_locale: { eq: "en" }) {
      ...SiteFooter
    }
    fi: contentfulSite(node_locale: { eq: "fi" }) {
      ...SiteFooter
    }
  }
`;
