import React, {
  ReactElement,
  useState,
  FocusEvent,
  ChangeEvent,
  InvalidEvent,
} from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useColorMode,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { TRANS } from './misc';

export type InputTextProps = {
  type?: 'text' | 'email' | 'url' | 'password';
  inputProps?: Omit<InputProps, 'as'>;
  componentLocale?: Locale;
} & FormControlProps;

export const InputText = ({
  label,
  type = 'text',
  inputProps = {},
  componentLocale,
  sx,
  ...rest
}: InputTextProps): ReactElement => {
  const { colorMode } = useColorMode();
  const { locale, lang } = useLocale(componentLocale);
  const { value, onFocus, onBlur, onChange, onInvalid, ...inputRest } =
    inputProps;
  const [floatLabel, setFloatLabel] = useState(!!value);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    !floatLabel && setFloatLabel(true);
    onFocus && onFocus(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    floatLabel && !event.target.value && setFloatLabel(false);
    onBlur && onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checkValidity();
    !floatLabel
      ? event.target.value && setFloatLabel(true)
      : !event.target.value &&
        event.target !== document.activeElement &&
        setFloatLabel(false);
    onChange && onChange(event);
  };

  const handleInvalid = (event: InvalidEvent<HTMLInputElement>) => {
    const {
      valueMissing,
      tooShort,
      tooLong,
      typeMismatch,
      patternMismatch,
      badInput,
    } = event.target.validity;
    event.target.setCustomValidity(
      valueMissing
        ? TRANS.invalidValueMissing[locale]
        : tooShort
        ? TRANS.invalidTooShort[locale]
        : tooLong
        ? TRANS.invalidTooLong[locale]
        : typeMismatch && type === 'email'
        ? TRANS.invalidTypeMismatchEmail[locale]
        : typeMismatch && type === 'url'
        ? TRANS.invalidTypeMismatchUrl[locale]
        : patternMismatch
        ? TRANS.invalidPatternMismatch[locale]
        : badInput
        ? TRANS.invalidOther[locale]
        : ''
    );
    onInvalid && onInvalid(event);
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl
      lang={lang}
      marginY={'1.5em'}
      sx={{
        '& .floatingLabel': {
          backgroundColor: 'system.inputBackground',
          borderColor: 'system.inputBackground',
          borderRadius: 'm',
          borderWidth: 2,
          cursor: floatLabel ? undefined : 'text',
          marginX: '0.6rem',
          marginY: '0.85rem',
          paddingX: '0.5rem',
          position: 'absolute',
          left: 0,
          top: 0,
          transformOrigin: 'left top',
          transform: floatLabel
            ? 'scale(0.875) translateY(-1.75rem)'
            : undefined,
          zIndex: 2,
        },
        '&:hover .floatingLabel': {
          borderColor: floatLabel ? 'system.inputPlaceholder' : undefined,
        },
        '&:focus-within .floatingLabel': {
          borderColor: 'system.focus',
        },
        '& [class*="required-indicator"]': {
          color: colorMode === 'dark' ? 'inherit' : 'system.error',
        },
        ...sx,
      }}
      {...rest}
    >
      <FormLabel className={'floatingLabel'}>{label}</FormLabel>
      <InputGroup>
        <Input
          value={value}
          autoComplete={'off'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onInvalid={handleInvalid}
          type={type === 'password' && show ? 'text' : type}
          backgroundColor={'system.inputBackground'}
          borderColor={'system.inputBackground'}
          borderRadius={'m'}
          borderWidth={2}
          focusBorderColor={'system.focus'}
          height={'3.5rem'}
          padding={'1em'}
          _hover={{ borderColor: 'system.inputPlaceholder' }}
          _focus={{ borderColor: 'system.focus' }}
          {...inputRest}
        />
        {type === 'password' && (
          <InputRightElement height={'3.5rem'} width={'3.5rem'}>
            <Button
              variant={'unstyled'}
              onClick={handleClick}
              backgroundColor={'system.inputBackground'}
              borderRadius={'half'}
              borderWidth={2}
              color={show ? 'system.focus' : 'system.inputPlaceholder'}
              height={'2rem'}
              width={'2rem'}
              padding={0}
              _hover={{ borderColor: 'system.focus', color: 'system.focus' }}
            >
              {show ? <ViewIcon aria-hidden /> : <ViewOffIcon aria-hidden />}
              <VisuallyHidden>{TRANS.toggle[locale]}</VisuallyHidden>
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};
