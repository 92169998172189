import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Logo = (props: IconProps): ReactElement => (
  <Icon
    height={'100%'}
    width={'100%'}
    viewBox="0 0 400 472"
    fill={'ui.primary'}
    {...props}
  >
      <path d="m64,166c-18.24,0-32-12.9-32-30h20c0,6.87,6.22,10,12,10s12-3.13,12-10c0-7.37-4.18-9.94-15.24-14.89-9.86-4.42-24.76-11.09-24.76-29.11,0-15.07,11.78-26,28-26s28,10.93,28,26h-20c0-5.42-5.59-6-8-6s-8,.58-8,6c0,4.21,2.18,6.04,12.93,10.86,10.78,4.83,27.07,12.13,27.07,33.14,0,17.1-13.76,30-32,30Zm32,90v-68h-20v68c0,6.87-6.22,10-12,10s-12-3.13-12-10h-20c0,17.1,13.76,30,32,30s32-12.9,32-30Zm182-112h-44v-76h-20v96h64v-20Zm0,120h-38.21l38.21-59.05v-16.95h-64v20h38.21l-38.21,59.05v16.95h64v-20Zm-54,6h0,0Zm144-6h-38.21l38.21-59.05v-16.95h-64v20h38.21l-38.21,59.05v16.95h64v-20Zm-54,6h0,0Zm22-104c-27.57,0-50-22.43-50-50s22.43-50,50-50,50,22.43,50,50-22.43,50-50,50Zm0-80c-16.54,0-30,13.46-30,30s13.46,30,30,30,30-13.46,30-30-13.46-30-30-30Zm-178,320c-27.57,0-50-22.43-50-50s22.43-50,50-50,50,22.43,50,50-22.43,50-50,50Zm0-80c-16.54,0-30,13.46-30,30s13.46,30,30,30,30-13.46,30-30-13.46-30-30-30Zm-13.25-258l-35.75,96h21.48l23.52-63.15,23.52,63.15h21.48l-35.75-96h-18.51Zm0,120l-35.75,96h21.48l23.52-63.15,23.52,63.15h21.48l-35.75-96h-18.51Zm231.25,240H24v20h352v-20Zm0-404H24v20h352v-20ZM96,384h-33.32c6.79-6.56,20.79-20.33,21.03-20.56,3.68-3.59,12.29-11.99,12.29-25.44,0-17.65-14.36-32-32-32s-32,14.35-32,32h20c0-6.5,5.5-12,12-12s12,5.5,12,12c0,4.64-2.96,7.91-6.26,11.13-.29.29-37.74,36.59-37.74,36.59v18.28h64v-20Zm188,0h-33.32c6.79-6.56,20.79-20.33,21.03-20.56,3.68-3.59,12.29-11.99,12.29-25.44,0-17.65-14.36-32-32-32s-32,14.35-32,32h20c0-6.5,5.5-12,12-12s12,5.5,12,12c0,4.64-2.96,7.91-6.26,11.13-.29.29-37.74,36.59-37.74,36.59v18.28h64v-20Zm52,22c-18.2,0-33-14.8-33-33h20c0,7.17,5.83,13,13,13s13-5.83,13-13-5.83-13-13-13h-6l-7.59-16.51,13.28-15.49h-29.69v-20h58v17.7l-14.67,17.11c11.58,5.13,19.67,16.73,19.67,30.19,0,18.2-14.8,33-33,33Z"/>
  </Icon>
);
