import React, { ReactElement, ReactNode } from 'react';
import type { PageProps } from 'gatsby';
import { PageContext, PageContextData } from '../../context/PageContext';
import { EventCollectionQuery } from '../../templates/EventCollection';
import { EventPostQuery } from '../../templates/EventPost';
import { FrontpageQuery } from '../../templates/Frontpage';
import { GenericQuery } from '../../templates/Generic';
import { NewsCollectionQuery } from '../../templates/NewsCollection';
import { NewsPostQuery } from '../../templates/NewsPost';
import { formatDateTime } from '../../utils/datetime';
import { truncate } from '../../utils/string';
import { Auth } from '../Auth';
import { FooterWithQuery } from '../Footer/withQuery';
import { Head } from '../Head';
import { Layout } from '../Layout';
import { NavigationWithQuery } from '../Navigation/withQuery';
import { richTextAsString } from '../RichTextAsParagraph';

export type AppProps = {
  children: ReactNode;
} & Omit<PageProps, 'children'>;

export const App = ({
  children,
  data,
  location,
  pageContext,
}: AppProps): ReactElement => {
  const template = (pageContext as PageContextData).template;
  const locale = (pageContext as PageContextData).locale;

  /* META TITLE
    - Frontpage:            [Site title]
    - Generic:              Salojazz | [Page title]
    - Event collection:     Salojazz | [Page title]
    - News collection:      SaloJazz | [Page title]
    - Event post:           [Page title], [date], [venue]
    - News post:            [Page title]
     */

  const start =
    template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.start
      : undefined;

  const venueName =
    template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.venueName
      : undefined;

  const title =
    template === 'Frontpage'
      ? (data as FrontpageQuery)?.contentfulPageFrontpage?.title
      : template === 'Generic'
      ? 'SaloJazz | ' + (data as GenericQuery)?.contentfulPageGeneric?.title
      : template === 'EventCollection'
      ? 'SaloJazz | ' +
        (data as EventCollectionQuery)?.contentfulPageEventCollection?.title
      : template === 'NewsCollection'
      ? 'SaloJazz | ' +
        (data as NewsCollectionQuery)?.contentfulPageNewsCollection?.title
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.title +
        (start ? `, ${formatDateTime(locale, start, true, true)}` : '') +
        (venueName ? `, ${venueName}` : '')
      : template === 'NewsPost'
      ? (data as NewsPostQuery)?.contentfulPageNewsPost?.title
      : undefined;

  /* META DESCRIPTION
  - Frontpage:            [Site description]
  - Generic:              [Page description]
  - Event collection:     [Page description]
  - News collection:      [Page description]
  - Event post:           [Page description] OR truncated [Page content]
  - News post:            [Page description] OR truncated [Page content]
  */

  const content =
    template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.content
      : template === 'NewsPost'
      ? (data as NewsPostQuery)?.contentfulPageNewsPost?.content
      : undefined;

  const description =
    template === 'Generic'
      ? (data as GenericQuery)?.contentfulPageGeneric?.description
      : template === 'EventCollection'
      ? (data as EventCollectionQuery)?.contentfulPageEventCollection
          ?.description
      : template === 'NewsCollection'
      ? (data as NewsCollectionQuery)?.contentfulPageNewsCollection?.description
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.description ||
        truncate(richTextAsString(content?.raw), 256)
      : template === 'NewsPost'
      ? (data as NewsPostQuery)?.contentfulPageNewsPost?.description ||
        truncate(richTextAsString(content?.raw), 256)
      : undefined;

  /* META IMAGE
  - Frontpage:            [Page image] or [Site image]
  - Generic:              [Site image]
  - Event collection:     [Site image]
  - News collection:      [Site image]
  - Event post:           [Page image]
  - News post:            [Page image]
  */

  const image =
    template === 'Frontpage'
      ? (data as FrontpageQuery)?.contentfulPageFrontpage?.image?.fixed?.src
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.image?.fixed?.src
      : template === 'NewsPost'
      ? (data as NewsPostQuery)?.contentfulPageNewsPost?.image?.fixed?.src
      : undefined;

  /* OTHER */

  const type = template === 'NewsPost' ? 'article' : undefined;
  const hideFromSearchEngines = !template;
  const hideNavigation = !template;
  const hideFooter = !template;

  return (
    <PageContext value={pageContext as PageContextData}>
      <Head
        hideFromSearchEngines={hideFromSearchEngines}
        pageTitle={title}
        pageDescription={description}
        pageImage={image}
        pageType={type}
      />
      <Auth location={location}>
        <Layout
          navigation={<NavigationWithQuery />}
          footer={<FooterWithQuery />}
          hideNavigation={hideNavigation}
          hideFooter={hideFooter}
        >
          {children}
        </Layout>
      </Auth>
    </PageContext>
  );
};
