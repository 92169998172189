import React from 'react';
import { Link } from '../Link';

export const TRANS = {
  copyright: {
    en: 'Copyright',
    fi: 'Tekijänoikeudet',
  },
};

export const MOCK_FOOTER = {
  contact: {
    order: ['Facebook', 'Instagram'],
    facebook: 'https://www.facebook.com/example',
    instagram: 'https://www.instagram.com/example',
  },
  area1Header: 'Aaa',
  area1Body:
    'Aaaaa aaaa aaaaa aaaa aaaa aaaaa aaaa aaaa aaaaa aaaa aaaa aaaaa aaaa',
  area2Header: 'Bbb',
  area2Body: 'Bbb bbb bbbbbb bbbb bbb bbbbbb bbbb bbb bbbbbb bbbb',
  area3Items: ['111', '222', '333'].map((item) => (
    <Link
      key={item}
      href={`/${item}`}
      variant={'reverseUnderline'}
      color={'text.light'}
    >
      {item}
    </Link>
  )),
};
