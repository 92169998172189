import React, { Children, ReactElement } from 'react';
import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import { LayoutGridVariant, LAYOUT_GRID_SX } from './misc';

export type LayoutGridItemProps = {
  variant?: LayoutGridVariant;
} & GridItemProps;

export const LayoutGridItem = ({
  children,
  variant = 'mainNarrow',
  ...rest
}: LayoutGridItemProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <GridItem {...LAYOUT_GRID_SX[variant]} {...rest}>
      {children}
    </GridItem>
  );

export type LayoutGridProps = {
  visualizeColumns?: boolean;
} & GridProps;

export const LayoutGrid = ({
  children,
  visualizeColumns,
  minHeight,
  ...rest
}: LayoutGridProps): ReactElement | null =>
  isEmptyNode(children) && !visualizeColumns ? null : (
    <Grid
      gridAutoFlow={'column'}
      gridTemplateColumns={{
        base: '20px repeat(3, 0) repeat(8, 1fr) repeat(3, 0) 20px',
        s: '20px 0 repeat(2, calc((100vw - 600px) / 6.2)) repeat(8, 1fr) repeat(2, calc((100vw - 600px) / 6.2)) 0 20px',
        l: '20px auto repeat(12, minmax(95px, 104px)) auto 20px',
        xl: '1fr repeat(14, minmax(auto, 104px)) 1fr',
      }}
      minHeight={minHeight || visualizeColumns ? 100 : undefined}
      {...rest}
    >
      {children}
      {!visualizeColumns
        ? null
        : Array(16)
            .fill(0)
            .map((_, i) => (
              <GridItem
                key={i}
                colStart={i + 1}
                colEnd={i + 2}
                rowStart={1}
                rowEnd={Children.count(children) + 1}
                borderColor={'text.default'}
                borderRightWidth={1}
                fontSize={'xs'}
                opacity={0.2}
                textAlign={'center'}
              />
            ))}
    </Grid>
  );
