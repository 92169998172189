import React, { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type StrongProps = Omit<TextProps, 'as'>;

export const Strong = ({
  children,
  ...rest
}: StrongProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Text as="strong" fontWeight={700} {...rest}>
      {children}
    </Text>
  );
