export const TRANS = {
  contactUs: {
    en: 'Contact us',
    fi: 'Ota yhteyttä',
  },
  close: {
    en: 'Close',
    fi: 'Sulje',
  },
  name: {
    en: 'Name',
    fi: 'Nimi',
  },
  email: {
    en: 'Email',
    fi: 'Sähköposti',
  },
  email2: {
    en: 'Please enter your email again',
    fi: 'Anna sähköpostiosoitteesi uudelleen',
  },
  subject: {
    en: 'Subject',
    fi: 'Aihe',
  },
  message: {
    en: 'Message',
    fi: 'Viesti',
  },
  submit: {
    en: 'Submit',
    fi: 'Lähetä',
  },
  success: {
    en: 'Your message has been sent!',
    fi: 'Viestisi on lähetetty!',
  },
  failure: {
    en: 'Oh no! Something went wrong.',
    fi: 'Voi ei! Jotain meni pieleen.',
  },
};
