import 'focus-visible';
import { Theme, extendTheme } from '@chakra-ui/react';
import { RecursiveOptional } from '../utils/types';
import { breakpoints } from './breakpoints';
import { fontSizes } from './fontSizes';

const spaceAndSizes = Object.fromEntries(
  [
    0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20, 24, 28,
    32, 36, 40, 44, 48, 56, 60, 64, 72, 80, 96,
  ].map((number) => [number, `${number}px`])
);

export const customTheme: {
  breakpoints: { [key: string]: string };
  radii: { [key: string]: string };
} & RecursiveOptional<Theme> = {
  config: {
    initialColorMode: 'system',
    useSystemColorMode: false,
  },
  breakpoints: breakpoints.withUnit,
  fonts: {
    body: '"Lato", sans-serif',
    heading: '"DMSerifText", serif',
    mono: '"RobotoMono", monospace',
  },
  fontSizes: fontSizes.relative,
  radii: {
    zero: '0px',
    s: '2px',
    m: '4px',
    l: '8px',
    half: '50%',
  },
  space: spaceAndSizes,
  sizes: spaceAndSizes,
  styles: {
    global: {
      'html': {
        fontSize: fontSizes.absolute.s,
        lineHeight: 1.25,
        fontWeight: 400,
        scrollBehavior: 'smooth',
      },
      'body': {
        minWidth: 280,
        minHeight: 250,
        overflowY: 'scroll',
      },
      'button, [role="button"]': {
        cursor: 'auto !important',
      },
    },
  },
};

export const theme = extendTheme(customTheme);
