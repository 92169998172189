import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';
import { Icons, ICONS } from './misc';

export const Icon = Object.fromEntries(
  Object.entries(ICONS).map(([key, value]) => [
    key,
    createIcon({
      displayName: key,
      ...value,
      defaultProps: { 'aria-hidden': true },
    }),
  ])
) as { [Property in Icons]: ComponentWithAs<'svg', IconProps> };
