import React, { Children, ReactElement, useMemo } from 'react';
import { Grid, GridItem, GridProps, Skeleton } from '@chakra-ui/react';
import { useResizeDetector } from 'react-resize-detector';
import { getNextDivisor, isPrime } from '../../utils/number';
import { isEmptyNode } from '../../utils/other';

export type SmartGridProps = {
  gridRowStyles?: GridProps['sx'][];
  minColumnWidth: number;
  maxColumnWidth?: number;
} & Omit<
  GridProps,
  | 'gridArea'
  | 'area'
  | 'gridTemplateAreas'
  | 'templateAreas'
  | 'gridAutoColumns'
  | 'autoColumns'
  | 'gridTemplateColumns'
  | 'templateColumns'
>;

export const SmartGrid = ({
  children,
  gridRowStyles = [],
  minColumnWidth,
  maxColumnWidth,
  sx,
  ...rest
}: SmartGridProps): ReactElement | null => {
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  const array = useMemo(() => Children.toArray(children), [children]);
  const approximate = useMemo(() => isPrime(array.length), [array.length]);
  const columns = useMemo(
    () =>
      width === undefined || width < minColumnWidth
        ? 2
        : width < array.length * minColumnWidth
        ? getNextDivisor(
            array.length,
            Math.floor(width / minColumnWidth),
            approximate
          )
        : array.length,
    [approximate, array.length, minColumnWidth, width]
  );

  const rowStyles = useMemo(
    () =>
      Object.fromEntries(
        new Array(array.length)
          .fill(gridRowStyles)
          .flat()
          .map((style, i) => [
            `& > div:nth-of-type(n+${i * columns + 1}):nth-of-type(-n+${
              i * columns + columns
            })`,
            style,
          ])
      ),
    [array.length, columns, gridRowStyles]
  );

  const max = useMemo(
    () => (maxColumnWidth ? maxColumnWidth + 'px' : '1fr'),
    [maxColumnWidth]
  );

  return isEmptyNode(children) ? null : (
    <Skeleton isLoaded={!!width} endColor="white" speed={0} fadeDuration={0.4}>
      <Grid
        ref={ref}
        templateColumns={`repeat(${columns}, minmax(0, ${max}))`}
        sx={{ ...sx, ...rowStyles }}
        {...rest}
      >
        {array.map((child, i) => (
          <GridItem key={i}>{child}</GridItem>
        ))}
      </Grid>
    </Skeleton>
  );
};
