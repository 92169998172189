import React, { ReactElement } from 'react';
import { navigate } from 'gatsby-link';
import {
  Box,
  Collapse,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  VisuallyHidden,
} from '@chakra-ui/react';
import { usePageContext } from '../../context/PageContext';
import { useLocale } from '../../utils/hooks';
import { Locale, LOCALES } from '../../utils/types';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TRANS } from './misc';

export type SwitcherLanguageProps = {
  componentLocale?: Locale;
};

export const SwitcherLanguage = ({
  componentLocale,
}: SwitcherLanguageProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);
  const { translationPath } = usePageContext();

  const changeLocale = () => {
    navigate(translationPath);
  };

  return (
    <Menu placement={'bottom-start'} offset={[0, 5]}>
      {({ isOpen }) => (
        <Box lang={lang} display={'inline-block'}>
          <MenuButton
            as={Button}
            variant={'ghost'}
            _active={{ backgroundColor: 'ui.primary', color: 'text.onPrimary' }}
            borderRadius={'half'}
            lineHeight={0}
            padding={4}
            fontSize={'1.75rem'}
          >
            <Icon.Globe />
            <VisuallyHidden>{TRANS.language[locale]}</VisuallyHidden>
          </MenuButton>
          <MenuList
            as={Collapse}
            animateOpacity={false}
            in={isOpen}
            backgroundColor={'ui.surface'}
            borderColor={'ui.surface'}
            borderRadius={'m'}
            borderWidth={0}
            minWidth={'unset'}
            padding={0}
            transform={'none !important'}
            rootProps={{ borderRadius: 'm', boxShadow: 'base' }}
            overflow={'hidden'}
          >
            <MenuOptionGroup
              type={'radio'}
              value={locale}
              onChange={() => changeLocale()}
            >
              {LOCALES.map((loc) => (
                <MenuItemOption
                  key={loc}
                  value={loc}
                  transitionDuration={'fast'}
                  _hover={{
                    backgroundColor: 'ui.primary',
                    color: 'text.onPrimary',
                  }}
                  _focus={{
                    backgroundColor: 'ui.primary',
                    color: 'text.onPrimary',
                  }}
                >
                  {TRANS[loc][loc]}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Box>
      )}
    </Menu>
  );
};
