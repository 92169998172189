const initial = [
  {
    key: 'xs',
    name: 'Extra-small',
    absolute: '14px',
    relative: '0.875rem',
  },
  {
    key: 's',
    name: 'Small',
    absolute: '16px',
    relative: '1rem',
  },
  {
    key: 'm',
    name: 'Medium',
    absolute: '20px',
    relative: '1.25rem',
  },
  {
    key: 'l',
    name: 'Large',
    absolute: '24px',
    relative: '1.5rem',
  },
  {
    key: 'xl',
    name: 'Extra-large',
    absolute: '32px',
    relative: '2rem',
  },
  {
    key: '2xl',
    name: 'Extra-extra-large',
    absolute: '40px',
    relative: '2.5rem',
  },
  {
    key: '3xl',
    name: 'Extra-extra-extra-large',
    absolute: '48px',
    relative: '3rem',
  },
  {
    key: '4xl',
    name: 'Extra-extra-extra-extra-large',
    absolute: '80px',
    relative: '5rem',
  },
];

const absolute = Object.fromEntries(
  initial.map(({ key, absolute }) => [key, absolute])
);

const relative = Object.fromEntries(
  initial.map(({ key, relative }) => [key, relative])
);

export const fontSizes = { absolute, relative, array: initial };
