import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { usePageContext } from '../../context/PageContext';
import { Locale } from '../../utils/types';
import { DateTime } from '../DateTime';
import { Hyphenate } from '../Hyphenate';
import { LinkCard } from '../LinkCard';
import { NestedLocale } from '../NestedLocale';
import { RichTextData } from '../RichText';
import { richTextAsString } from '../RichTextAsParagraph';

export type LinkCardNewsPostProps = {
  slug: string;
  language: Locale;
  title: string;
  createdAt: string;
  excerpt?: string;
  content?: RichTextData;
  lines?: number;
};

export const LinkCardNewsPost = ({
  slug,
  language,
  title,
  createdAt,
  excerpt,
  content,
  lines = 3,
}: LinkCardNewsPostProps): ReactElement => {
  const { newsCollectionPath } = usePageContext();
  const href = `${newsCollectionPath}/${slug}`;

  return (
    <LinkCard
      href={href}
      title={
        <Box marginBottom={'1rem'}>
          <NestedLocale componentLocale={language}>
            <Hyphenate>{title}</Hyphenate>
          </NestedLocale>
        </Box>
      }
      subtitle={<DateTime start={createdAt} dateOnly condensed />}
      tagline={
        <Box marginY={'1rem'}>
          <NestedLocale componentLocale={language}>
            <Text noOfLines={lines}>
              {excerpt || richTextAsString(content?.raw)}
            </Text>
          </NestedLocale>
        </Box>
      }
      spacerAfterTitle={true}
      spacerAfterSubtitle={false}
      spacerAfterTagline={false}
      withReadMore
    />
  );
};
