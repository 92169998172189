import React, { ReactElement, ReactNode } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';

export type LayoutProps = {
  children: ReactNode;
  navigation: ReactElement;
  footer: ReactElement;
  hideNavigation?: boolean;
  hideFooter?: boolean;
};

export const Layout = ({
  children,
  navigation,
  footer,
  hideNavigation,
  hideFooter,
}: LayoutProps): ReactElement => (
  <Flex flexDirection={'column'} minHeight={'100vh'}>
    <div id="top" />
    {hideNavigation ? null : navigation}
    {children}
    {hideFooter ? null : (
      <>
        <Spacer />
        {footer}
      </>
    )}
  </Flex>
);
