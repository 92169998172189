import { useEffect, useState } from 'react';
import { LinkCardEventPostProps } from '../../components/LinkCardEventPost';
import { isPast } from '../datetime';

type Events = LinkCardEventPostProps[];

export const useSortedEvents = (
  events: Events,
  idToExclude?: string
): { upcoming: Events; past: Events } => {
  const [update, setUpdate] = useState(true);
  const [upcoming, setUpcoming] = useState<Events>([]);
  const [past, setPast] = useState<Events>([]);

  useEffect(() => {
    if (update && events) {
      setUpcoming(
        events
          .filter(
            (post) =>
              (!idToExclude ? true : idToExclude !== post.contentful_id) &&
              (post.end ? !isPast(post.end) : !isPast(post.end || post.start))
          )
          .reverse()
      );
      setPast(
        events.filter(
          (post) =>
            (!idToExclude ? true : idToExclude !== post.contentful_id) &&
            (post.end ? isPast(post.end) : isPast(post.start))
        )
      );
      setUpdate(false);
    }
  }, [update, events, idToExclude]);

  useEffect(() => {
    if (
      !update &&
      upcoming[0] &&
      (upcoming[0].end ? isPast(upcoming[0].end) : isPast(upcoming[0].start))
    ) {
      setUpdate(true);
    }
  }, [update, upcoming]);

  return { upcoming, past };
};
