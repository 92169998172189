import { getRelativeLuminance, parseToRgb } from '.';

const BLACK = '#000000';
const WHITE = '#FFFFFF';

export const chooseBlackOrWhite = (color: string): string => {
  /* parse color as RGB array */
  const rgb = parseToRgb(color);

  /* if the color couldn't be parsed, return black */
  if (!rgb) {
    return BLACK;
  }

  /* otherwise, get the relative luminance */
  const luminance = getRelativeLuminance(rgb);

  /* choose black or white based on relative luminance */
  const chosen = luminance > 0.18 ? BLACK : WHITE;

  return chosen;
};
