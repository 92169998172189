export const TRANS = {
  invalidValueMissing: {
    en: 'This field is required',
    fi: 'Tämä kenttä on pakollinen',
  },
  invalidTooShort: {
    en: 'The text you entered is too short',
    fi: 'Antamasi teksti on liian lyhyt',
  },
  invalidTooLong: {
    en: 'The text you entered is too long',
    fi: 'Antamasi teksti on liian pitkä',
  },
};
