import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  Center,
  forwardRef,
  HStack,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useIsStorybookContext } from '../../context/IsStorybookContext';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Icon } from '../Icon';
import {
  CTA_SX_SIZES,
  CTA_SX_VARIANTS,
  CTASize,
  CTAVariant,
  TRANS,
} from './misc';

export type CTAProps = {
  componentLocale?: Locale;
  fullWidth?: boolean;
  size?: CTASize;
  to?: string;
  variant?: CTAVariant;
  withoutIcon?: boolean;
} & Omit<ChakraLinkProps, 'isExternal' | 'size' | 'variant'>;

export const CTA = forwardRef(
  (
    {
      children,
      componentLocale,
      fullWidth,
      size = 'm',
      variant = 'outline',
      href,
      to,
      withoutIcon,
      ...rest
    }: CTAProps,
    ref
  ): ReactElement | null => {
    const { locale, lang } = useLocale(componentLocale);
    const isStorybook = useIsStorybookContext();

    return (
      <ChakraLink
        ref={ref}
        {...(isStorybook &&
          href?.startsWith('#') && {
            onClick: (event) => {
              event.preventDefault();
              const element = document.getElementById(href.slice(1));
              element?.scrollIntoView();
              element?.focus();
            },
          })}
        lang={lang}
        display={'inline-block'}
        width={fullWidth ? '100%' : 'auto'}
        {...(CTA_SX_SIZES[size] || CTA_SX_SIZES.m)}
        {...(CTA_SX_VARIANTS[variant] || CTA_SX_VARIANTS.solid)}
        {...(href
          ? { href: href, isExternal: true }
          : to
          ? { as: GatsbyLink, to: to }
          : { as: Box, cursor: 'auto', _hover: {}, sx: {} })}
        {...rest}
      >
        <Center width={variant === 'underline' ? 'auto' : '100%'}>
          <HStack className={'cta-underline'}>
            <Box>{children || TRANS.readMore[locale]}</Box>
            {href && !withoutIcon ? (
              <Box>
                <ExternalLinkIcon aria-hidden marginBottom={'0.2em'} />
                <VisuallyHidden> {TRANS.newTab[locale]}</VisuallyHidden>
              </Box>
            ) : href ? (
              <VisuallyHidden>{TRANS.newTab[locale]}</VisuallyHidden>
            ) : !withoutIcon ? (
              <Icon.AngleRight
                height={'1em'}
                width={'auto'}
                viewBox={'7 4 10 16'}
              />
            ) : null}
          </HStack>
        </Center>
      </ChakraLink>
    );
  }
);
