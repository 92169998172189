import React, { ReactElement } from 'react';
import {
  Box,
  Collapse,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorMode,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale, COLOR_MODES } from '../../utils/types';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TRANS } from './misc';

export type SwitcherColorModeProps = {
  componentLocale?: Locale;
};

export const SwitcherColorMode = ({
  componentLocale,
}: SwitcherColorModeProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);
  const { colorMode, toggleColorMode } = useColorMode();

  const changeTheme = () => toggleColorMode();

  return (
    <Menu placement={'bottom-start'} offset={[0, 5]}>
      {({ isOpen }) => (
        <Box lang={lang} display={'inline-block'}>
          <MenuButton
            as={Button}
            variant={'ghost'}
            _active={{ backgroundColor: 'ui.primary', color: 'text.onPrimary' }}
            borderRadius={'half'}
            lineHeight={0}
            padding={4}
            fontSize={'1.75rem'}
          >
            {colorMode === 'light' ? <Icon.Sun /> : <Icon.Moon />}
            <VisuallyHidden>{TRANS.theme[locale]}</VisuallyHidden>
          </MenuButton>
          <MenuList
            as={Collapse}
            animateOpacity={false}
            in={isOpen}
            backgroundColor={'ui.surface'}
            borderColor={'ui.surface'}
            borderRadius={'m'}
            borderWidth={0}
            minWidth={'unset'}
            padding={0}
            transform={'none !important'}
            rootProps={{ borderRadius: 'm', boxShadow: 'base' }}
            overflow={'hidden'}
          >
            <MenuOptionGroup
              type={'radio'}
              value={colorMode}
              onChange={() => changeTheme()}
            >
              {COLOR_MODES.map((theme) => (
                <MenuItemOption
                  key={theme}
                  value={theme}
                  transitionDuration={'fast'}
                  _hover={{
                    backgroundColor: 'ui.primary',
                    color: 'text.onPrimary',
                  }}
                  _focus={{
                    backgroundColor: 'ui.primary',
                    color: 'text.onPrimary',
                  }}
                >
                  {TRANS[theme][locale]}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Box>
      )}
    </Menu>
  );
};
