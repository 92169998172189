import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useToken,
} from '@chakra-ui/react';
import { usePageContext } from '../../context/PageContext';
import { ColorScheme } from '../../theme/colors';
import { Locale } from '../../utils/types';
import { UNDERLINE_SX } from '../CTA/misc';
import { DateTime } from '../DateTime';
import { Hyphenate } from '../Hyphenate';
import { ImageFromUrlProps } from '../ImageFromUrl';
import { ImageRasterProps } from '../ImageRaster';
import { LinkCard } from '../LinkCard';
import { NestedLocale } from '../NestedLocale';
import { RichTextData } from '../RichText';
import { richTextAsString } from '../RichTextAsParagraph';
import { LinkCardEventPostVariant } from './misc';

export type LinkCardEventPostProps = {
  contentful_id?: string;
  colorScheme?: ColorScheme;
  variant?: LinkCardEventPostVariant;
  slug: string;
  language?: Locale;
  title: string;
  start: string;
  end?: string;
  excerpt?: string;
  content?: RichTextData;
  image: {
    gatsbyImageData?: ImageRasterProps['image'];
    fixed?: { src: ImageFromUrlProps['image'] };
  };
};

export const LinkCardEventPost = ({
  colorScheme = 'green',
  variant = 'horizontal',
  slug,
  language,
  title,
  start,
  end,
  excerpt,
  content,
  image,
}: LinkCardEventPostProps): ReactElement => {
  const { eventCollectionPath } = usePageContext();
  const href = `${eventCollectionPath}/${slug}`;

  const [focusOutline] = useToken('shadows', ['outline']);
  const backgroundColor = `${colorScheme}.500`;
  const color = 'text.onColor';

  return variant === 'horizontalSmaller' ? (
    <LinkBox
      borderRadius={'m'}
      paddingY={'0.1rem'}
      _focusWithin={{ boxShadow: focusOutline }}
      sx={{
        '.event-card-underline, .event-card-underline': {
          ...UNDERLINE_SX.base,
        },
        '&:hover .event-card-underline, &:focus .event-card-underline': {
          ...UNDERLINE_SX.interaction,
        },
      }}
    >
      <Stack
        align={'baseline'}
        direction={{ base: 'column', m: 'row-reverse' }}
        justify={{ base: 'flex-start', m: 'flex-end' }}
        spacing={{ base: '0.5em', m: '2em' }}
      >
        <LinkOverlay
          as={GatsbyLink}
          to={href}
          outline={'2px solid transparent'}
          fontWeight={700}
        >
          <NestedLocale componentLocale={language}>
            <span className={'event-card-underline'}>{title}</span>
          </NestedLocale>
        </LinkOverlay>
        <Box flexShrink={0}>
          <DateTime start={start} dateOnly condensed withoutIcon />
        </Box>
      </Stack>
    </LinkBox>
  ) : (
    <LinkCard
      href={href}
      title={
        <Box
          marginBottom={'1rem'}
          fontSize={
            variant === 'horizontalSmall' ? 'm' : { base: 'l', m: 'xl' }
          }
          color={color}
        >
          <NestedLocale componentLocale={language}>
            <Hyphenate>{title}</Hyphenate>
          </NestedLocale>
        </Box>
      }
      subtitle={<DateTime start={start} end={end} condensed />}
      tagline={
        variant === 'horizontalSmall' ? undefined : (
          <Box marginY={'1rem'}>
            <NestedLocale componentLocale={language}>
              <Text noOfLines={4}>
                {excerpt || richTextAsString(content?.raw)}
              </Text>
            </NestedLocale>
          </Box>
        )
      }
      withReadMore={variant !== 'horizontalSmall'}
      withArrow={variant === 'horizontalSmall'}
      spacerAfterTitle={variant !== 'horizontal'}
      spacerAfterSubtitle={false}
      image={image}
      imagePosition={variant === 'vertical' ? 'top' : 'left'}
      breakpoint={'s'}
      backgroundColor={backgroundColor}
      color={color}
      sx={{
        'height': '100%',
        '& .cardText':
          variant === 'horizontal'
            ? {
                marginX: { base: '1.5rem', m: '2.5rem' },
                marginY: { base: '1.2rem', m: '2.2rem' },
              }
            : {},
        '& .image':
          variant === 'horizontalSmall'
            ? {
                width: { base: '100%', s: '10em' },
                height: { base: '150px', xs: '50vw', s: 'auto' },
              }
            : variant === 'horizontal'
            ? {
                width: { base: '100%', s: '50%' },
                height: 'auto',
              }
            : {
                height: 'auto',
              },
      }}
    />
  );
};
