import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  Box,
  forwardRef,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useIsStorybookContext } from '../../context/IsStorybookContext';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { EM_SPACE } from '../../utils/string';
import { Locale } from '../../utils/types';
import { CONTACT_REGEX } from '../Contact/misc';
import { LinkVariant, LINK_SX_VARIANTS, TRANS } from './misc';

export type LinkProps = {
  componentLocale?: Locale;
  newTab?: boolean;
  variant?: LinkVariant;
} & Omit<ChakraLinkProps, 'isExternal' | 'variant'>;

export const Link = forwardRef(
  (
    {
      children: initialChildren,
      newTab,
      componentLocale,
      href: initialHref,
      variant = 'underline',
      ...rest
    }: LinkProps,
    ref
  ): ReactElement | null => {
    const { locale, lang } = useLocale(componentLocale);
    const isStorybook = useIsStorybookContext();
    const isMailto = initialHref?.startsWith('mailto:');
    const isTel = initialHref?.startsWith('tel:');
    const isContactLink = isMailto || isTel;

    const [href, setHref] = useState<string | undefined>(
      isContactLink ? undefined : initialHref
    );
    useEffect(
      () => (isContactLink ? setHref(initialHref) : undefined),
      [isContactLink, initialHref]
    );

    const [children, setChildren] = useState(initialChildren);
    useLayoutEffect(
      () =>
        Array.isArray(initialChildren) &&
        initialChildren.flat().every((child) => typeof child === 'string')
          ? setChildren(initialChildren.join(''))
          : setChildren(initialChildren),
      [initialChildren]
    );

    return isEmptyNode(children) ? null : (
      <ChakraLink
        ref={ref}
        href={href}
        {...(isStorybook &&
          href?.startsWith('#') && {
            onClick: (event) => {
              event.preventDefault();
              const element = document.getElementById(href.slice(1));
              element?.scrollIntoView();
              element?.focus();
            },
          })}
        isExternal={newTab}
        lang={lang}
        {...(LINK_SX_VARIANTS[variant] || LINK_SX_VARIANTS.underline)}
        {...rest}
      >
        {typeof children !== 'string'
          ? children
          : (isMailto && children.match(CONTACT_REGEX.email)) ||
            (isTel && children.match(CONTACT_REGEX.phone))
          ? children.split('').reduce<ReactNode[]>(
              (strings, string, i) => [
                ...strings,
                <Box key={i + string} as={'span'} display={'none'} aria-hidden>
                  {EM_SPACE}
                </Box>,
                string,
              ],
              []
            )
          : children}
        {newTab && (
          <>
            <ExternalLinkIcon
              aria-hidden
              boxSize={'0.8em'}
              marginBottom={'0.3em'}
              marginStart={'0.2em'}
            />
            <VisuallyHidden> {TRANS.newTab[locale]}</VisuallyHidden>
          </>
        )}
      </ChakraLink>
    );
  }
);
