import { fontSizes } from '../../theme/fontSizes';

export const HEADING_LEVELS = [1, 2, 3, 4, 5, 6] as const;

export type HeadingLevel = typeof HEADING_LEVELS[number];

const xxxl = parseInt(fontSizes.absolute['3xl']);
const xxxxl = parseInt(fontSizes.absolute['4xl']);

export const HEADING_SX = {
  1: {
    fontFamily: 'heading',
    fontSize: {
      base: xxxl,
      m: `calc(${xxxl}px + ${xxxxl - xxxl} * (100vw - ${900}px) / ${
        1500 - 900
      })`,
      xl: xxxxl,
    },
    fontWeight: '400',
    lineHeight: 1.1,
  },
  2: {
    fontFamily: 'heading',
    fontSize: '2xl',
    fontWeight: '400',
    lineHeight: 1.25,
  },
  3: {
    fontFamily: 'heading',
    fontSize: 'xl',
    fontWeight: '400',
    lineHeight: 1.25,
  },
  4: {
    fontFamily: 'heading',
    fontSize: 'l',
    fontWeight: '400',
    lineHeight: 1.25,
  },
  5: {
    fontFamily: 'body',
    fontSize: 'm',
    fontWeight: '700',
    lineHeight: 1.25,
  },
  6: {
    fontFamily: 'body',
    fontSize: 's',
    fontWeight: '700',
    lineHeight: 1.25,
  },
};

export const HEADING_HYPHENATION = {
  1: {
    base: 5,
    s: 7,
    l: 9,
  },
  2: {
    base: 6,
    s: 9,
    l: 11,
  },
  3: {
    base: 7,
    s: 12,
    l: 14,
  },
  4: {
    base: 9,
    s: 15,
    l: 18,
  },
  5: {
    base: 11,
    s: 19,
    l: 23,
  },
  6: {
    base: 13,
    s: 23,
    l: 29,
  },
};
