export const LAYOUT_GRID_VARIANTS = [
  'navigation',
  'heroWide',
  'heroNarrow',
  'right',
  'left',
  'mainWide',
  'mainNarrow',
  'popup',
  'footer',
] as const;

export type LayoutGridVariant = typeof LAYOUT_GRID_VARIANTS[number];

export const LAYOUT_GRID_VARIANT_NAMES = {
  navigation: 'Navigation',
  heroWide: 'Hero (wide)',
  heroNarrow: 'Hero (narrow)',
  right: '2-column (right)',
  left: '2-column (left)',
  mainWide: 'Main (1-column, wide)',
  mainNarrow: 'Main (1-column, narrow)',
  popup: 'Popup',
  footer: 'Footer',
};

export const LAYOUT_GRID_SX = {
  navigation: {
    colStart: 3,
    colEnd: -3,
  },
  heroWide: {
    colStart: 2,
    colEnd: -2,
  },
  heroNarrow: {
    colStart: 3,
    colEnd: -3,
  },
  mainWide: {
    colStart: 3,
    colEnd: -3,
  },
  mainNarrow: {
    colStart: 5,
    colEnd: -5,
  },
  left: {
    colStart: {
      base: 5,
      m: 3,
    },
    colEnd: {
      base: -5,
      m: 10,
    },
  },
  right: {
    colStart: {
      base: 5,
      m: 11,
    },
    colEnd: {
      base: -5,
      m: -3,
    },
  },
  popup: {
    colStart: 5,
    colEnd: -5,
  },
  footer: {
    colStart: 3,
    colEnd: -3,
  },
};
