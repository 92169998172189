import { spaceToSpace } from '.';
import { parseToRgb } from './parseToRgb';

export const hexToHsluv = (hex: string): number[] | null => {
  /* parse color as RGB array */
  const rgb = parseToRgb(hex);

  /* if the color couldn't be parsed, return null */
  if (!rgb) {
    return null;
  }

  /* do roundabout conversion because the library we're using gives the wrong result when converting directly from RGB to HSLuv */
  const xyz = spaceToSpace(rgb, 'rgb', 'xyz');
  const luv = spaceToSpace(xyz, 'xyz', 'luv');
  const lchuv = spaceToSpace(luv, 'luv', 'lchuv');
  const hsluv = spaceToSpace(lchuv, 'lchuv', 'hsluv');

  return hsluv;
};
