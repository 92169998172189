import React, { ReactElement /* useLayoutEffect, useState */ } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { siteConfig } from '../../../siteConfig';
import { usePageContext } from '../../context/PageContext';
import { trimFinalSlash } from '../../utils/string';

export type HeadProps = {
  hideFromSearchEngines?: boolean;
  pageTitle?: string;
  pageDescription?: string;
  pageImage?: string;
  pageType?: string;
};

type HeadSubquery = {
  title: string;
  description: string;
  image: {
    fixed: null | {
      src: string;
    };
  };
};

type HeadQuery = {
  en?: HeadSubquery;
  fi?: HeadSubquery;
};

export const Head = ({
  hideFromSearchEngines,
  pageTitle,
  pageDescription,
  pageImage,
  pageType,
}: HeadProps): ReactElement => {
  const { locale, currentPath } = usePageContext();

  const data: HeadQuery = useStaticQuery(query);
  const siteTitle = data[locale]?.title;
  const siteDescription = data[locale]?.description;
  const siteImage = data[locale]?.image?.fixed?.src;

  const title = pageTitle || siteTitle;
  // const [title, setTitle] = useState(pageTitle || siteTitle);
  // useLayoutEffect(
  //   () => setTitle(pageTitle || siteTitle),
  //   [pageTitle, siteTitle]
  // );
  const description = pageDescription || siteDescription;
  const image = pageImage || siteImage;
  const type = pageType || 'website';

  const author = siteConfig.NAME;
  const url = trimFinalSlash(siteConfig.URL) + currentPath;

  return (
    <Helmet defer={false}>
      {/* General tags */}
      <html lang={locale} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="author" content={author} />

      {/* OpenGraph tags */}
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:author" content={author} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {hideFromSearchEngines || process.env.GATSBY_REQUIRE_AUTH === 'true' ? (
        <meta name="robots" content="noindex" />
      ) : (
        <script type="text/javascript">
          {`(function(window, document, dataLayerName, id) {\nwindow[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');\nfunction stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}\nvar isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);\nvar qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";\ntags.async=!0,tags.src="https://salojazz.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);\n!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);\n})(window, document, 'dataLayer', 'bf2dbd2a-057c-46cd-82dd-60affc9ae5c5');`}
        </script>
      )}
    </Helmet>
  );
};

const query = graphql`
  {
    en: contentfulSite(node_locale: { eq: "en" }) {
      ...SiteHead
    }
    fi: contentfulSite(node_locale: { eq: "fi" }) {
      ...SiteHead
    }
  }
`;
