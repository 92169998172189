export const TRANS = {
  primary: {
    en: 'Primary',
    fi: 'Ensisijainen',
  },
  frontpage: {
    en: 'Frontpage',
    fi: 'Etusivu',
  },
  showMenu: {
    en: 'Show menu',
    fi: 'Näytä valikko',
  },
};

export const MOCK_NAVIGATION = [
  {
    title: 'Apple',
    slug: 'apple',
  },
  {
    title: 'Banana',
    slug: 'banana',
  },
  {
    title: 'Cherry',
    slug: 'cherry',
  },
];
