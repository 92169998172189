export type RecursiveRequired<Type> = Required<
  NonNullable<{
    [Key in keyof Type]: RecursiveRequired<Type[Key]>;
  }>
>;

export type RecursiveOptional<Type> = Partial<{
  [Key in keyof Type]?: RecursiveOptional<Type[Key]> | null;
}>;

export const COLOR_MODES = ['light', 'dark'] as const;

export type ColorMode = typeof COLOR_MODES[number];

export const LOCALES = ['en', 'fi'] as const;

export type Locale = typeof LOCALES[number];

export type LocaleOrUndefined = Locale | undefined;

export const TEMPLATES = [
  'Frontpage',
  'Generic',
  'EventCollection',
  'EventPost',
  'NewsCollection',
  'NewsPost',
] as const;

export type Template = typeof TEMPLATES[number];
