import React, { ReactElement, ReactNode } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Spacer,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Contact, ContactProps } from '../Contact';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { LayoutGrid, LayoutGridItem } from '../LayoutGrid';
import { NavigationHome } from '../Navigation';
import { TRANS } from './misc';

export type FooterProps = {
  componentLocale?: Locale;
  contact?: ContactProps['data'];
  area1Header?: ReactNode;
  area1Body?: ReactNode;
  area2Header?: ReactNode;
  area2Body?: ReactNode;
  area3Items?: ReactNode[];
};

export const Footer = ({
  componentLocale,
  contact,
  area1Header,
  area1Body,
  area2Header,
  area2Body,
  area3Items,
}: FooterProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Box
      as={'footer'}
      lang={lang}
      backgroundColor={'ui.background'}
      color={'text.default'}
      paddingY={'2rem'}
      marginTop={'2rem'}
    >
      <LayoutGrid>
        <LayoutGridItem variant={'footer'}>
          <Flex marginX={-6}>
            <NavigationHome />
            <Spacer />
            <Contact center color={'white'} data={contact} iconSize={'2rem'} />
          </Flex>
          <SimpleGrid
            gridTemplateColumns={{ base: '1fr', s: '1fr 1fr', m: '1.5fr 1fr' }}
            gridTemplateAreas={{
              base: '"column2"\n"column1"',
              s: '"column1 column2"',
            }}
            gridColumnGap={'4em'}
            gridRowGap={'1em'}
            marginY={'1em'}
          >
            <Box gridArea={'column1'}>
              <Heading level={4} as={'p'} withoutLine>
                {area1Header}
              </Heading>
              {area1Body}
            </Box>
            <Box gridArea={'column2'}>
              <Heading level={4} as={'p'} withoutLine>
                {area2Header}
              </Heading>
              {area2Body}
            </Box>
          </SimpleGrid>
          <Divider />
          <Flex
            color={'text.light'}
            direction={{ base: 'column', s: 'row-reverse' }}
            fontSize={'xs'}
          >
            <Stack direction={{ base: 'column', s: 'row' }} spacing={'1em'}>
              {area3Items?.map((item, i) => (
                <Box key={`footer-area3-${i}`}>{item}</Box>
              ))}
            </Stack>
            <Spacer minHeight={'2em'} />
            <Box>
              <VisuallyHidden>{TRANS.copyright[locale]}</VisuallyHidden>{' '}
              <span aria-hidden>©</span> {new Date().getFullYear()} SaloJazz
            </Box>
          </Flex>
        </LayoutGridItem>
      </LayoutGrid>
    </Box>
  );
};
