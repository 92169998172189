import React, {
  ReactElement,
  useState,
  FocusEvent,
  ChangeEvent,
  InvalidEvent,
} from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  Textarea,
  TextareaProps,
  useColorMode,
} from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { TRANS } from './misc';

export type InputTextareaProps = {
  textareaProps?: Omit<TextareaProps, 'as'>;
  componentLocale?: Locale;
} & FormControlProps;

export const InputTextarea = ({
  label,
  textareaProps = {},
  componentLocale,
  sx,
  ...rest
}: InputTextareaProps): ReactElement => {
  const { colorMode } = useColorMode();
  const { locale } = useLocale(componentLocale);
  const { value, onFocus, onBlur, onChange, onInvalid, ...textareaRest } =
    textareaProps;
  const [floatLabel, setFloatLabel] = useState(!!value);

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    !floatLabel && setFloatLabel(true);
    onFocus && onFocus(event);
  };

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    floatLabel && !event.target.value && setFloatLabel(false);
    onBlur && onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.target.checkValidity();
    !floatLabel
      ? event.target.value && setFloatLabel(true)
      : !event.target.value &&
        event.target !== document.activeElement &&
        setFloatLabel(false);
    onChange && onChange(event);
  };

  const handleInvalid = (event: InvalidEvent<HTMLTextAreaElement>) => {
    const { valueMissing, tooShort, tooLong } = event.target.validity;
    event.target.setCustomValidity(
      valueMissing
        ? TRANS.invalidValueMissing[locale]
        : tooShort
        ? TRANS.invalidTooShort[locale]
        : tooLong
        ? TRANS.invalidTooLong[locale]
        : ''
    );
    onInvalid && onInvalid(event);
  };

  return (
    <FormControl
      marginY={'1.5em'}
      sx={{
        '& .floatingLabel': {
          backgroundColor: 'system.inputBackground',
          borderColor: 'system.inputBackground',
          borderRadius: 'm',
          borderWidth: 2,
          cursor: floatLabel ? undefined : 'text',
          marginX: '0.6rem',
          marginY: '0.85rem',
          paddingX: '0.5rem',
          position: 'absolute',
          left: 0,
          top: 0,
          transformOrigin: 'left top',
          transform: floatLabel
            ? 'scale(0.875) translateY(-1.75rem)'
            : undefined,
          zIndex: 2,
        },
        '&:hover .floatingLabel': {
          borderColor: floatLabel ? 'system.inputPlaceholder' : undefined,
        },
        '&:focus-within .floatingLabel': {
          borderColor: 'system.focus',
        },
        '& [class*="required-indicator"]': {
          color: colorMode === 'dark' ? 'inherit' : 'system.error',
        },
        ...sx,
      }}
      {...rest}
    >
      <FormLabel className={'floatingLabel'}>{label}</FormLabel>
      <Textarea
        value={value}
        autoComplete={'off'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onInvalid={handleInvalid}
        resize={'vertical'}
        backgroundColor={'system.inputBackground'}
        borderColor={'system.inputBackground'}
        borderRadius={'m'}
        borderWidth={2}
        focusBorderColor={'system.focus'}
        height={'7rem'}
        padding={'1em'}
        _hover={{ borderColor: 'system.inputPlaceholder' }}
        _focus={{ borderColor: 'system.focus' }}
        {...textareaRest}
      />
    </FormControl>
  );
};
