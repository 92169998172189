import React, { ReactElement } from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import {
  BUTTON_SX_SIZES,
  BUTTON_SX_VARIANTS,
  ButtonSize,
  ButtonVariant,
} from './misc';

export type ButtonProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
} & Omit<ChakraButtonProps, 'size' | 'variant' | 'as'>;

export const Button = forwardRef(
  (
    { children, size = 'm', variant = 'solid', ...rest }: ButtonProps,
    ref
  ): ReactElement | null =>
    isEmptyNode(children) ? null : (
      <ChakraButton
        ref={ref}
        {...(BUTTON_SX_SIZES[size] || BUTTON_SX_SIZES.m)}
        {...(BUTTON_SX_VARIANTS[variant] || BUTTON_SX_VARIANTS.solid)}
        {...rest}
      >
        {children}
      </ChakraButton>
    )
);
