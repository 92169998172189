import { EN_DASH } from '../string';
import { localizeDate } from './localizeDate';
import { localizeDateAndTime } from './localizeDateAndTime';
import { localizeDateMaybeTime } from './localizeDateMaybeTime';
import { localizeTime } from './localizeTime';

export const formatDateTimeRange = (
  locale: string,
  start: string,
  end: string,
  condensed?: boolean
): string => {
  /* extended ISO 8601 strings look something like:
  - 2022-07-12T10:28:53+00:00
  - 2022-07-12T10:28:53Z
  so we can split them at the T to get the date and time separately
  */
  const [startDate, startTime] = start.split('T');
  const [endDate, endTime] = end.split('T');

  /* if start and end span from the beginning to the end of a single day, return something like "31/12/2000" (depending on output format) */
  if (
    startDate === endDate &&
    startTime.startsWith('00:00') &&
    endTime.startsWith('23:59')
  ) {
    return localizeDate(locale, start, condensed);
  } else if (startDate === endDate) {
    /* otherwise, if start and end are within a single day, return something "31/12/2000 18:00 – 20:00" (depending on output format) */
    return (
      localizeDateAndTime(locale, start, condensed) +
      ` ${EN_DASH} ` +
      localizeTime(locale, end)
    );
  } else {
    /* otherwise, return something "01/01/2000 18:00 - 31/12/2000 20:00" (depending on output format) */
    return (
      localizeDateMaybeTime(locale, start, condensed) +
      ` ${EN_DASH} ` +
      localizeDateMaybeTime(locale, end, condensed)
    );
  }
};
