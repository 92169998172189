import React, { ReactElement, ReactNode } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, Inline, Block } from '@contentful/rich-text-types';
import { usePageContext } from '../../context/PageContext';
import { useLocale } from '../../utils/hooks';
import { NO_BREAK_SPACE, protocolizeUrl } from '../../utils/string';
import { Locale } from '../../utils/types';
import { Callout } from '../Callout';
import { Code } from '../Code';
import { CTA } from '../CTA';
import { Disclosure } from '../Disclosure';
import { Divider } from '../Divider';
import { EmbedGoogleMaps } from '../EmbedGoogleMaps';
import { EmbedSoundCloud } from '../EmbedSoundCloud';
import { EmbedSpotify } from '../EmbedSpotify';
import { EmbedYouTube } from '../EmbedYouTube';
import { Emphasis } from '../Emphasis';
import { Figure, FigureProps } from '../Figure';
import { FigureGrid } from '../FigureGrid';
import { HeadingForRichText } from '../HeadingForRichText';
import { Link } from '../Link';
import { LinkCardEventPost } from '../LinkCardEventPost';
import { LinkCardNewsPost } from '../LinkCardNewsPost';
import { List } from '../List';
import { ListItem } from '../ListItem';
import { NestedLocale } from '../NestedLocale';
import { Paragraph } from '../Paragraph';
import { Quote } from '../Quote';
import { Strong } from '../Strong';
import { TRANS } from './misc';

export type RichTextData = {
  raw?: string;
  references?: ContentfulRichTextGatsbyReference[];
};

export type RichTextOptions = {
  renderText?: {
    (text: string): ReactNode;
  };
  renderMark?: {
    bold?: (text: ReactNode) => ReactNode;
    italic?: (text: ReactNode) => ReactNode;
    code?: (text: ReactNode) => ReactNode;
  };
  renderNode?: {
    [Key in INLINES | BLOCKS]?: (
      node: Inline | Block,
      children: ReactNode
    ) => ReactNode;
  };
};

export type RichTextProps = {
  componentLocale?: Locale;
  data?: RichTextData;
  footer?: boolean;
};

export const RichText = ({
  componentLocale,
  data,
  footer,
}: RichTextProps): ReactElement | null => {
  const { locale } = useLocale(componentLocale);
  const {
    eventCollectionPath,
    newsCollectionPath,
    locale: pageLocale,
  } = usePageContext();

  const options: RichTextOptions = {
    renderText: (text: string) =>
      text
        .split(NO_BREAK_SPACE)
        .join('')
        .split('\n')
        .reduce<ReactNode[]>(
          (strings, string, i) => [
            ...strings,
            i === 0 ? '' : <br key={`br-${i}`} />,
            string,
          ],
          []
        )
        .filter((node) => node),
    renderMark: {
      bold: (text) => <Strong>{text}</Strong>,
      italic: (text) => <Emphasis>{text}</Emphasis>,
      code: (text) => <Code>{text}</Code>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          href={protocolizeUrl(node.data.uri)}
          newTab={
            !node.data.uri.startsWith('mailto:') &&
            !node.data.uri.startsWith('tel:')
          }
        >
          {children}
        </Link>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link
          href={
            (node.data.target.__typename === 'ContentfulPageEventPost'
              ? eventCollectionPath
              : node.data.target.__typename === 'ContentfulPageEventPost'
              ? newsCollectionPath
              : `/${pageLocale}`) + `/${node.data.target.slug}`
          }
        >
          {children}
        </Link>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.HEADING_2]: (_, children) => (
        <HeadingForRichText level={2}>{children}</HeadingForRichText>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <HeadingForRichText level={3}>{children}</HeadingForRichText>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <HeadingForRichText level={4}>{children}</HeadingForRichText>
      ),
      [BLOCKS.LIST_ITEM]: (_, children) => (
        <ListItem sx={{ '& p': { marginY: '0.5em' } }}>{children}</ListItem>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <List
          ordered
          level={
            6 -
            JSON.stringify(node.content).split('"nodeType":"ordered-list"')
              .length
          }
          marginBottom={'2.5em'}
        >
          {children}
        </List>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <List
          level={
            6 -
            JSON.stringify(node.content).split('"nodeType":"unordered-list"')
              .length
          }
          marginBottom={'2.5em'}
        >
          {children}
        </List>
      ),
      [BLOCKS.QUOTE]: (_, children) => <Quote>{children}</Quote>,
      [BLOCKS.HR]: () => <Divider />,
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const data = node.data?.target;
        if (!data) {
          return;
        } else if (data.__typename) {
          return (
            <Text as={'span'} color={'system.error'}>
              {' '}
              [{data.__typename} {TRANS.notSupported[locale]}]
            </Text>
          );
        } else {
          return <span> [{TRANS.dataNotSupported[locale]}]</span>;
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const data = node.data?.target;
        if (!data) {
          return;
        } else if (data.__typename === 'ContentfulPageEventPost') {
          if (!footer) {
            return (
              <Box marginY={'1rem'}>
                <LinkCardEventPost variant={'horizontalSmall'} {...data} />
              </Box>
            );
          }
          const { title, slug, ...rest } = data;
          return (
            <Box marginY={'1rem'}>
              <CTA to={`/${locale}/${slug}`} variant={'underline'} {...rest}>
                {title}
              </CTA>
            </Box>
          );
        } else if (data.__typename === 'ContentfulPageNewsPost') {
          if (!footer) {
            return (
              <Box marginY={'1rem'}>
                <LinkCardNewsPost {...data} />
              </Box>
            );
          }
          const { title, slug, ...rest } = data;
          return (
            <Box marginY={'1rem'}>
              <CTA to={`/${locale}/${slug}`} variant={'underline'} {...rest}>
                {title}
              </CTA>
            </Box>
          );
        } else if (data.__typename?.startsWith('ContentfulPage')) {
          const { title, slug, ...rest } = data;
          return (
            <Box marginY={'1rem'}>
              <CTA
                to={`/${locale}/${slug}`}
                variant={footer ? 'underline' : 'solid'}
                fullWidth={!footer}
                {...rest}
              >
                {title}
              </CTA>
            </Box>
          );
        } else if (data.__typename === 'ContentfulCtaExternal') {
          const { text, url } = data;
          return (
            <Box marginY={'1rem'}>
              <CTA
                href={url}
                variant={footer ? 'underline' : 'solid'}
                fullWidth={!footer}
              >
                {text}
              </CTA>
            </Box>
          );
        } else if (data.__typename === 'ContentfulDisclosure') {
          const { title, content, colorScheme } = data;
          return (
            <Disclosure title={title} colorScheme={colorScheme}>
              <RichText data={content} />
            </Disclosure>
          );
        } else if (data.__typename === 'ContentfulEmbedGoogleMaps') {
          return (
            <EmbedGoogleMaps
              url={data.url}
              title={'Google Maps: ' + data.title}
              rememberConsent
            />
          );
        } else if (data.__typename === 'ContentfulEmbedSoundCloud') {
          return (
            <EmbedSoundCloud
              url={data.url}
              title={'SoundCloud: ' + data.title}
              visualPlayer={data.useVisualPlayer}
              size={data.size}
              rememberConsent
            />
          );
        } else if (data.__typename === 'ContentfulEmbedSpotify') {
          return (
            <EmbedSpotify
              url={data.url}
              title={'Spotify: ' + data.title}
              size={data.size}
              rememberConsent
            />
          );
        } else if (data.__typename === 'ContentfulEmbedYouTube') {
          return (
            <EmbedYouTube
              url={data.url}
              title={'YouTube: ' + data.title}
              aspectRatio={data.aspectRatio}
              rememberConsent
            />
          );
        } else if (data.__typename === 'ContentfulFigure') {
          return (
            <Figure
              image={data.image}
              imageForDarkTheme={data.imageForDarkTheme}
              caption={
                data?.caption?.raw ? (
                  <Box
                    sx={{
                      'display': 'inline',
                      '& *': { display: 'inline' },
                    }}
                  >
                    <RichText data={data.caption} />
                  </Box>
                ) : undefined
              }
              textAlternative={data.textAlternative}
              url={data.url}
            />
          );
        } else if (data.__typename === 'ContentfulFigureGrid') {
          const figures = data.figures.map(
            ({
              image,
              imageForDarkTheme,
              textAlternative,
              url,
              caption,
            }: {
              caption: RichTextData;
            } & FigureProps) => ({
              image,
              imageForDarkTheme,
              textAlternative,
              url,
              caption: (
                <Box
                  sx={{
                    'display': 'inline',
                    '& *': { display: 'inline' },
                  }}
                >
                  <RichText data={caption} />
                </Box>
              ),
            })
          );
          return <FigureGrid data={figures} size={data.size} />;
        } else if (data.__typename) {
          return (
            <Callout variant={'negative'}>
              <Code>{data.__typename}</Code> {TRANS.notSupported[locale]}
            </Callout>
          );
        } else {
          return (
            <Callout variant={'negative'}>
              {TRANS.dataNotSupported[locale]}
            </Callout>
          );
        }
      },
    },
  };

  return !data ? null : (
    <NestedLocale componentLocale={locale}>
      {data?.raw &&
        renderRichText(
          { raw: data.raw, references: data?.references || [] },
          options
        )}
    </NestedLocale>
  );
};
