import React, { ReactElement } from 'react';
import { Box, BoxProps, HStack, VisuallyHidden } from '@chakra-ui/react';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { Locale } from '../../utils/types';
import {
  CalloutAltVariant,
  CalloutVariant,
  CALLOUT_ICONS,
  CALLOUT_ALTS,
  CALLOUT_SX,
} from './misc';

export type CalloutProps = {
  componentLocale?: Locale;
  iconAltVariant?: CalloutAltVariant;
  variant?: CalloutVariant;
} & BoxProps;

export const Callout = ({
  children,
  componentLocale,
  iconAltVariant = 'guidance',
  variant = 'neutral',
  ...rest
}: CalloutProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);
  const Icon = CALLOUT_ICONS[variant];

  return isEmptyNode(children) ? null : (
    <Box
      lang={lang}
      borderLeftWidth={5}
      borderRadius={'m'}
      marginY={'1em'}
      paddingX={'1em'}
      paddingY={'1.1em'}
      {...CALLOUT_SX[variant]}
      {...rest}
    >
      <HStack spacing={'1em'} align={'center'}>
        <Icon fontSize={'1.5em'} />
        <Box>
          <VisuallyHidden>
            {CALLOUT_ALTS[iconAltVariant][variant][locale]}:{' '}
          </VisuallyHidden>
          {children}
        </Box>
      </HStack>
    </Box>
  );
};
