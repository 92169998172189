import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Collapse,
  Flex,
  Grid,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Global, Interpolation, Theme } from '@emotion/react';
import { useContactFormContext } from '../../context/ContactFormContext';
import { useLocale } from '../../utils/hooks';
import { isEmptyNode } from '../../utils/other';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { Callout } from '../Callout';
import { CTA } from '../CTA';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { InputText } from '../InputText';
import { InputTextarea } from '../InputTextarea';
import { Link } from '../Link';
import { NestedLocale } from '../NestedLocale';
import { TRANS } from './misc';

export type ContactFormProps = {
  componentLocale?: Locale;
  largeButton?: boolean;
  title?: string;
  description?: ReactNode;
  successMessage?: ReactNode;
  failureMessage?: ReactNode;
};

export const ContactForm = ({
  componentLocale,
  largeButton,
  title,
  description,
  successMessage,
  failureMessage,
}: ContactFormProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);
  title = isEmptyNode(title) ? TRANS.contactUs[locale] : title;
  description = isEmptyNode(description) ? undefined : description;
  successMessage = isEmptyNode(successMessage)
    ? TRANS.success[locale]
    : successMessage;
  failureMessage = isEmptyNode(failureMessage)
    ? failureMessage
    : failureMessage;

  const isCentered = useBreakpointValue({ base: false, m: true });
  const { initialValues, values, setValues } = useContactFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [key, setKey] = useState(Date.now());
  const ref = useRef<null | HTMLDivElement>(null);

  const handleReset = () => {
    setValues(initialValues);
    setKey(Date.now());
  };

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    (isFailure || isSuccess) && handleReset();
    isFailure && setIsFailure(false);
    isSuccess && setIsSuccess(false);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleFocus = () => {
    isFailure && setIsFailure(false);
    isSuccess && setIsSuccess(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (values.email2) {
      setIsLoading(false);
      handleReset();
      handleClose();
      return;
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: Object.entries({
        'form-name': 'contact',
        'name': values.name,
        'email': values.email,
        'subject': values.subject,
        'message': values.message,
      })
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + '=' + encodeURIComponent(value)
        )
        .join('&'),
    })
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          throw Error(response.statusText);
        }
      })
      .then(() => {
        handleReset();
        setIsSuccess(true);
      })
      .catch(() => {
        setIsFailure(true);
      })
      .finally(() => {
        setIsLoading(false);
        ref.current?.focus({ preventScroll: true });
        setTimeout(() => {
          ref.current?.offsetParent?.parentElement?.scrollTo({
            top: 5000,
            behavior: 'smooth',
          });
        }, 500);
      });
  };

  return (
    <NestedLocale componentLocale={locale}>
      <Link
        as={'button'}
        aria-controls={'chakra-modal-contact'}
        aria-expanded={isOpen}
        aria-haspopup={'dialog'}
        lang={lang}
        onClick={handleOpen}
        variant={'reverseUnderline'}
        fontFamily={largeButton ? 'heading' : undefined}
        fontSize={largeButton ? 'l' : undefined}
        fontWeight={700}
      >
        {title}
      </Link>
      <Box display={'none'}>
        <form data-netlify="true" name={'contact'}>
          <label>
            Subject
            <input name="subject" />
          </label>
          <label>
            Message
            <input name="message" />
          </label>
          <label>
            Name
            <input name="name" />
          </label>
          <label>
            Email
            <input name="email" />
          </label>
          <button type={'submit'}>Submit</button>
        </form>
      </Box>
      <Modal
        isCentered={isCentered}
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior={'outside'}
        id={'contact'}
      >
        <ModalOverlay
          backgroundColor={'ui.shadow'}
          backdropFilter={'blur(60px)'}
        />
        <ModalContent
          aria-label={title}
          lang={lang}
          backgroundColor={'ui.surface'}
          boxShadow={'base'}
          padding={{ base: '1rem', s: '2.5rem' }}
          margin={{ base: '0.5rem', xs: '1rem', s: '2rem' }}
          maxWidth={900}
          transitionDuration={'faster'}
        >
          <Global
            styles={
              {
                'body': { paddingRight: '0px !important' },
                'nav': { paddingRight: '10px !important' },
                '.chakra-modal__content-container': {
                  overflowY: 'scroll !important',
                },
              } as unknown as Interpolation<Theme>
            }
          />
          <Box>
            <Flex align={'baseline'} justify={'space-between'}>
              <Heading
                as={'p'}
                level={3}
                lineHeight={1}
                marginTop={0}
                outline={'2px solid transparent'}
                tabIndex={0}
              >
                {title}
              </Heading>
              <Button
                onClick={handleClose}
                variant={'ghost'}
                borderRadius={'half'}
                fontSize={'1.75rem'}
                marginEnd={description ? undefined : '-0.5em'}
                padding={4}
              >
                <Icon.Times />
                <VisuallyHidden>{TRANS.close[locale]}</VisuallyHidden>
              </Button>
            </Flex>
          </Box>
          <Box>{!description ? null : description}</Box>
          <form key={key} onFocus={handleFocus} onSubmit={handleSubmit}>
            <Grid gridGap={'1rem'}>
              <Grid
                gridGap={'1rem'}
                gridTemplateColumns={{ base: '1fr', m: '1fr 1fr' }}
                marginTop={'1.5rem'}
              >
                <InputText
                  type={'text'}
                  id={'name'}
                  label={TRANS.name[locale]}
                  isRequired
                  inputProps={{
                    autoComplete: 'name',
                    onChange: handleChange,
                    value: values.name,
                  }}
                  marginY={0}
                />
                <InputText
                  type={'email'}
                  id={'email'}
                  label={TRANS.email[locale]}
                  isRequired
                  inputProps={{
                    autoComplete: 'email',
                    onChange: handleChange,
                    value: values.email,
                  }}
                  marginY={0}
                />
                <InputText
                  type={'email'}
                  id={'email2'}
                  label={TRANS.email2[locale]}
                  inputProps={{
                    autoComplete: 'email',
                    onChange: handleChange,
                    value: values.email2,
                  }}
                  display={'none'}
                  marginY={0}
                />
              </Grid>
              <InputText
                type={'text'}
                id={'subject'}
                label={TRANS.subject[locale]}
                isRequired
                inputProps={{
                  onChange: handleChange,
                  value: values.subject,
                }}
                marginY={0}
              />
              <InputTextarea
                id={'message'}
                label={TRANS.message[locale]}
                isRequired
                textareaProps={{
                  onChange: handleChange,
                  value: values.message,
                }}
                marginY={0}
              />
              <CTA
                as={Button}
                variant={'solid'}
                type={'submit'}
                fullWidth
                isLoading={isLoading}
              >
                {TRANS.submit[locale]}
              </CTA>
            </Grid>
          </form>
          <Box ref={ref} tabIndex={-1} />
          <Collapse in={isFailure} transition={{ enter: { duration: 0.25 } }}>
            <Callout variant={'negative'} marginBottom={0}>
              {failureMessage}
            </Callout>
          </Collapse>
          <Collapse in={isSuccess} transition={{ enter: { duration: 0.25 } }}>
            <Callout variant={'positive'} marginBottom={0}>
              {successMessage}
            </Callout>
          </Collapse>
        </ModalContent>
      </Modal>
    </NestedLocale>
  );
};
