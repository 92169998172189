export const TRANS = {
  theme: {
    en: 'Color theme',
    fi: 'Väritila',
  },
  light: {
    en: 'Light',
    fi: 'Vaalea',
  },
  dark: {
    en: 'Dark',
    fi: 'Tumma',
  },
};
