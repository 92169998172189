import React, { ReactElement, ReactNode } from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  Center,
  Flex,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  Spacer,
  useToken,
} from '@chakra-ui/react';
import { CTA } from '../CTA';
import { UNDERLINE_SX } from '../CTA/misc';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Image, ImageProps } from '../Image';

export type LinkCardProps = {
  href: string;
  title: ReactNode;
  subtitle?: ReactNode;
  tagline?: ReactNode;
  image?: ImageProps['image'];
  imagePosition?: 'top' | 'right' | 'bottom' | 'left';
  breakpoint?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  spacerAfterTitle?: boolean;
  spacerAfterSubtitle?: boolean;
  spacerAfterTagline?: boolean;
  withReadMore?: boolean;
  withArrow?: boolean;
} & Omit<LinkBoxProps, 'href' | 'title'>;

export const LinkCard = ({
  href,
  title,
  subtitle,
  tagline,
  image,
  imagePosition = 'left',
  breakpoint = 's',
  spacerAfterTitle = true,
  spacerAfterSubtitle = true,
  spacerAfterTagline = false,
  withReadMore,
  withArrow,
  backgroundColor = 'ui.surface',
  sx,
  ...rest
}: LinkCardProps): ReactElement => {
  const [focusOutline] = useToken('shadows', ['outline']);

  return (
    <LinkBox
      backgroundColor={backgroundColor}
      borderRadius={'m'}
      isolation={'isolate'}
      overflow={'hidden'}
      transitionProperty={'box-shadow, transform'}
      transitionDuration={'slow'}
      _hover={{
        boxShadow: 'hover',
        transform: 'translateY(-0.3rem)',
      }}
      _focusWithin={{ boxShadow: focusOutline }}
      sx={{
        ...sx,
        '& .cta .cta-underline': UNDERLINE_SX.base,
        '&:hover .cta .cta-underline': UNDERLINE_SX.interaction,
      }}
      {...rest}
    >
      <Flex
        direction={
          imagePosition === 'top'
            ? 'column'
            : imagePosition === 'right'
            ? { base: 'column', [breakpoint]: 'row-reverse' }
            : imagePosition === 'bottom'
            ? 'column-reverse'
            : { base: 'column', [breakpoint]: 'row' }
        }
        height={'100%'}
        width={'100%'}
      >
        {image && (
          <Flex
            className={'image'}
            flexShrink={0}
            opacity={0.9}
            width={
              imagePosition === 'right' || imagePosition === 'left'
                ? { base: '100%', [breakpoint]: '10em' }
                : '100%'
            }
            sx={{ '& img': { objectFit: 'cover' } }}
          >
            <Image image={image} />
          </Flex>
        )}
        <Flex
          className={'cardText'}
          marginX={'1.5rem'}
          marginY={'1.2rem'}
          flexGrow={1}
        >
          <Flex direction={'column'} flexGrow={1}>
            <Heading
              level={4}
              as={'div'}
              withoutLine
              color={'inherit'}
              marginTop={0}
              marginBottom={0}
            >
              <LinkOverlay
                as={GatsbyLink}
                to={href}
                outline={'2px solid transparent'}
              >
                {title}
              </LinkOverlay>
            </Heading>
            {spacerAfterTitle ? <Spacer /> : null}
            <Box>{subtitle}</Box>
            {spacerAfterSubtitle ? <Spacer /> : null}
            <Box>{tagline}</Box>
            {spacerAfterTagline ? <Spacer /> : null}
            {withReadMore ? (
              <Box marginBottom={-4}>
                <CTA
                  variant={'underline'}
                  className={'cta'}
                  fullWidth={false}
                  color={'inherit'}
                />
              </Box>
            ) : null}
          </Flex>
          {withArrow ? (
            <Center marginStart={'1rem'}>
              <Icon.AngleRight
                height={'1em'}
                width={'auto'}
                viewBox={'7 4 9 16'}
              />
            </Center>
          ) : null}
        </Flex>
      </Flex>
    </LinkBox>
  );
};
