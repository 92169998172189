import { COLOR_SCHEMES } from '../../theme/colors';

export const DISCLOSURE_COLOR_SCHEMES = [
  ...COLOR_SCHEMES,
  'gray',
  'outline',
] as const;

export type DisclosureColorScheme = typeof DISCLOSURE_COLOR_SCHEMES[number];

export const TRANS = {
  show: { en: 'Show', fi: 'Näytä' },
  hide: { en: 'Hide', fi: 'Piilota' },
};
