import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { ContactFormWithQuery } from '../ContactForm/withQuery';
import { Navigation, NavigationProps } from '.';

export type NavigationWithQueryProps = Omit<NavigationProps, 'data'>;

type NavigationSubquery = {
  navigationPages: NavigationProps['data'];
};

type NavigationQuery = {
  en: NavigationSubquery;
  fi: NavigationSubquery;
};

const finnishOnly = process.env.FINNISH_ONLY === 'true';

export const NavigationWithQuery = (): ReactElement => {
  const data: NavigationQuery = useStaticQuery(query);
  const { locale = 'fi' } = usePageContext();

  return (
    <Navigation
      data={data[locale].navigationPages}
      hideLanguageSwitcher={finnishOnly}
      Form={ContactFormWithQuery}
    />
  );
};

const query = graphql`
  {
    en: contentfulSite(node_locale: { eq: "en" }) {
      ...SiteNavigation
    }
    fi: contentfulSite(node_locale: { eq: "fi" }) {
      ...SiteNavigation
    }
  }
`;
