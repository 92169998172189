import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  Collapse,
  Flex,
  Hide,
  HStack,
  Show,
  Spacer,
  Square,
  useColorMode,
  useOutsideClick,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { useResizeDetector } from 'react-resize-detector';
import { colors } from '../../theme/colors';
import { usePageContext } from '../../context/PageContext';
import { Button } from '../Button';
import { ContactForm, ContactFormProps } from '../ContactForm';
import { Icon } from '../Icon';
import { SwitcherLanguage } from '../SwitcherLanguage';
import { LayoutGrid, LayoutGridItem } from '../LayoutGrid';
import { Link, LinkProps } from '../Link';
import { Logo } from '../Logo';
import { SkipLink } from '../SkipLink';
import { TRANS } from './misc';
import { SwitcherColorMode } from '../SwitcherColorMode';

export const NavigationHome = (): ReactElement => {
  const { locale } = usePageContext();

  return (
    <Box display={'flex'}>
      <Link
        as={GatsbyLink}
        to={`/${locale}`}
        color={'ui.primary'}
        variant={'box'}
      >
        <Square size={'2.5rem'} padding={2}>
          <Logo />
          <VisuallyHidden>{TRANS.frontpage[locale]}</VisuallyHidden>
        </Square>
      </Link>
    </Box>
  );
};

type NavigationLinkProps = {
  to: string;
  title: string;
} & LinkProps;

const NavigationLink = ({ to, title, ...rest }: NavigationLinkProps) => (
  <Link
    as={GatsbyLink}
    to={to}
    partiallyActive={true}
    activeStyle={{
      backgroundSize: '100% 2px',
      backgroundPositionX: '0%',
    }}
    variant={'reverseUnderline'}
    fontWeight={700}
    display={'inline-block'}
    {...rest}
  >
    {title}
  </Link>
);

export type NavigationProps = {
  data?: {
    title: string;
    slug: string;
  }[];
  hideLanguageSwitcher?: boolean;
  Form?: FC<ContactFormProps>;
};

export const Navigation = ({
  data,
  hideLanguageSwitcher,
  Form = ContactForm,
}: NavigationProps): ReactElement => {
  const { colorMode } = useColorMode();
  const backgroundColor = colors[colorMode].object.ui.background;
  const backgroundColorTransparent = backgroundColor + 'BB';

  const { locale, location } = usePageContext();

  const { height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  const [expanded, setExpanded] = useState(false);
  useEffect(() => setExpanded(false), [location?.pathname]);
  const expandableRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: expandableRef,
    handler: () => expanded && setExpanded(false),
  });

  return (
    <>
      <Box
        ref={ref}
        as={'nav'}
        aria-label={TRANS.primary[locale]}
        backdropFilter={'blur(30px)'}
        backgroundColor={
          expanded ? backgroundColor : backgroundColorTransparent
        }
        boxShadow={expandableRef?.current && expanded ? 'hover' : undefined}
        color={'text.default'}
        position={'fixed'}
        top={0}
        right={0}
        left={0}
        transitionProperty={'background-color, box-shadow'}
        transitionDuration={'normal'}
        zIndex={1000}
      >
        <LayoutGrid>
          <LayoutGridItem variant={'navigation'} position={'relative'}>
            <Box>
              <Global
                styles={{
                  html: { scrollPaddingTop: (height || 40) + 20 },
                }}
              />
              <SkipLink.Origin
                id={'main'}
                labels={{
                  en: 'Skip to main content',
                  fi: 'Siirry pääsisältöön',
                }}
                zIndex={1001}
              />
              <Show above={'m'}>
                <Flex align={'center'} marginX={-6} marginY={16}>
                  <NavigationHome />
                  <Spacer />
                  <Flex
                    align={'baseline'}
                    display={'inline-flex'}
                    justify={'flex-end'}
                    maxHeight={'calc(100vh - 1rem)'}
                    overflowX={'hidden'}
                    overflowY={'auto'}
                    overscrollBehaviorY={'contain'}
                  >
                    {data?.map(({ slug, title }) => (
                      <Box key={slug} marginStart={'1rem'} padding={4}>
                        <NavigationLink
                          to={`/${locale}/${slug}`}
                          title={title}
                        />
                      </Box>
                    ))}
                    <Box marginX={'1rem'} padding={4}>
                      <Form />
                    </Box>
                  </Flex>
                  <HStack>
                    <SwitcherColorMode />
                    {hideLanguageSwitcher ? null : <SwitcherLanguage />}
                  </HStack>
                </Flex>
              </Show>
              <Hide above={'m'}>
                <Flex align={'center'} marginX={-6} marginY={16}>
                  <NavigationHome />
                  <Spacer />
                  <Button
                    aria-expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                    variant={'ghost'}
                    borderRadius={'half'}
                    lineHeight={0}
                    fontSize={'1.75rem'}
                    padding={4}
                  >
                    {expanded ? <Icon.Times /> : <Icon.Bars />}
                    <VisuallyHidden>{TRANS.showMenu[locale]}</VisuallyHidden>
                  </Button>
                </Flex>
                <Box
                  onBlur={({ relatedTarget }) =>
                    relatedTarget &&
                    !expandableRef?.current?.contains(relatedTarget) &&
                    expanded &&
                    setExpanded(false)
                  }
                  ref={expandableRef}
                  maxHeight={'calc(100vh - 6rem)'}
                  overflowX={'hidden'}
                  overflowY={'auto'}
                  overscrollBehaviorY={'contain'}
                >
                  <Collapse in={expanded}>
                    {data?.map(({ slug, title }) => (
                      <Box key={slug} marginY={'1rem'}>
                        <NavigationLink
                          to={`/${locale}/${slug}`}
                          title={title}
                          fontFamily={'heading'}
                          fontSize={'l'}
                        />
                      </Box>
                    ))}
                    <Box marginY={'1rem'}>
                      <Form largeButton />
                    </Box>
                    <Box marginY={'3rem'}>
                      <HStack>
                        <SwitcherColorMode />
                        {hideLanguageSwitcher ? null : <SwitcherLanguage />}
                      </HStack>
                    </Box>
                  </Collapse>
                </Box>
              </Hide>
            </Box>
          </LayoutGridItem>
        </LayoutGrid>
      </Box>
      <Box height={'5rem' /* (height || 40) + 20 */} />
    </>
  );
};
