export const TRANS = {
  newTab: { en: ' (opens in a new tab)', fi: ' (avautuu uuteen välilehteen)' },
};

export const LINK_VARIANTS = ['underline', 'reverseUnderline', 'box'] as const;

export type LinkVariant = typeof LINK_VARIANTS[number];

const COMMON = {
  borderColor: 'transparent',
  borderRadius: 2,
  borderWidth: 2,
  textDecoration: 'none',
  transitionProperty: 'background-size, box-shadow, border-color',
  transitionDuration: 'slower',
  transitionTimingFunction: 'ease',
};

const UNDERLINE_COMMON = {
  backgroundImage: 'linear-gradient(currentColor, currentColor)',
  backgroundRepeat: 'no-repeat',
  borderTopWidth: 0,
  borderBottomWidth: 1,
  marginX: -2,
  paddingBottom: 2,
};

const UNDERLINE_IN = {
  backgroundPosition: '0% calc(100% - 1px)',
  backgroundSize: '100% 2px',
};

const UNDERLINE_OUT = {
  backgroundPosition: '100% calc(100% - 1px)',
  backgroundSize: '0% 2px',
};

export const LINK_SX_VARIANTS = {
  underline: {
    ...COMMON,
    ...UNDERLINE_COMMON,
    ...UNDERLINE_IN,
    _hover: {
      ...UNDERLINE_OUT,
    },
    _focus: {
      boxShadow: 'outlineWithoutOffset',
    },
  },
  reverseUnderline: {
    ...COMMON,
    ...UNDERLINE_COMMON,
    ...UNDERLINE_OUT,
    _hover: {
      ...UNDERLINE_IN,
    },
    _focus: {
      ...UNDERLINE_IN,
      boxShadow: 'outlineWithoutOffset',
    },
  },
  box: {
    ...COMMON,
    _hover: {
      borderColor: 'currentColor',
    },
    _focus: {
      boxShadow: 'outline',
    },
    sx: {
      '&:focus:not(:hover)': {
        outline: '2px solid white',
        outlineOffset: '-1px',
      },
    },
  },
};
